import React from 'react';

import MobileApp1 from "../assets/Meniaga_APPS-SS01@2x.png";
import MobileApp2 from "../assets/Meniaga_APPS-SS02@2x.png";

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import "../App.css";
import i18n from '../i18n.config';

function DesktopView() {
    return (
        <div className="third">
            <div className="row">
                <div className="col-md-5" style={{ position: "relative" }}>
                    <img src={MobileApp2} className="large-image" alt="mobile2" style={{ position: "absolute", top: 50 }} />
                    <img src={MobileApp1} className="small-image" alt="mobile1" style={{ position: "absolute", top: 270, left: 200 }} />
                </div>
                <div className="col-md-7" style={{ paddingTop: "120px" }}>
                    <div className="text-white text-raleway font-weight-bold mb-5" style={{ fontSize: "55px" }}>
                        {i18n.t('how')}
                    </div>
                    <div className="row text-raleway text-white" style={{ marginTop: "10px" }}>
                        <span className="circle-icon text-center mr-3">
                            1
                        </span>
                        {i18n.language == 'my' ?
                            <span className="tip-message">
                                Peniaga buka e-Gerai di app <span className="font-weight-bold font-italic">meniaga</span>
                            </span>
                            : <span className="tip-message">
                                Merchants open e-Gerai on the <span className="font-weight-bold font-italic">meniaga</span> app
                            </span>
                        }
                    </div>
                    <div className="row text-raleway text-white" style={{ marginTop: "20px" }}>
                        <span className="circle-icon text-center mr-3">
                            2
                        </span>
                        <span className="tip-message">
                            {i18n.t('step2')}
                        </span>
                        <span className="circle-icon text-center mr-3 ml-5">
                            3
                        </span>
                        {i18n.language == 'my' ?
                            <span className="tip-message">
                                Pembeli buka app <span className="font-weight-bold font-italic">meniaga</span>
                            </span>
                            : <span className="tip-message">
                                Buyer opens the <span className="font-weight-bold font-italic">meniaga</span> app
                            </span>
                        }
                    </div>
                    <div className="row text-raleway text-white" style={{ marginTop: "30px" }}>
                        <span className="circle-icon text-center mr-3 mt-2">
                            4
                        </span>
                        {i18n.language == 'my' ?
                            <span className="tip-message">
                                Pembeli memilih e-Gerai dari senarai, hanya<br />
                                e-Gerai dalam kawasan 10km ditunjukkan
                            </span>
                            : <span className='tip-message'>
                                Buyers choose the desired e-gerai from a list, only<br />
                                e-Gerai within 10km are shown
                            </span>
                        }
                    </div>
                    <div className="row text-raleway text-white" style={{ marginTop: "40px" }}>
                        <span className="circle-icon text-center mr-3 mt-2">
                            5
                        </span>
                        {i18n.language == 'my' ?
                            <span className="tip-message">
                                Pembeli membuat tempahan dan memilih kaedah penghantaran:<br />
                                dihantar penjual (delivery) atau pembeli ambil sendiri
                            </span>
                            : <span className="tip-message">
                                The buyer places an order and chooses the delivery method:<br />
                                sent by the seller (delivery) or the buyer picks it up
                            </span>
                        }
                    </div>
                    <div className="row text-raleway text-white" style={{ marginTop: "40px" }}>
                        <span className="circle-icon text-center mr-3 mt-2" style={{ lineHeight: "32px" }}>
                            6
                        </span>
                        {i18n.language == 'my' ?
                            <span className="tip-message">
                                Juadah belian dihantar/diambil<br />
                                dan pembeli membuat pembayaran tunai
                            </span>
                            : <span className="tip-message">
                                Food is delivered/picked up<br />
                                and buyers make a cash payment
                            </span>

                        }
                    </div>

                    <div className="row" style={{ marginTop: "40px" }}>
                        {i18n.language == 'my' ?
                            <a className="red-button text-raleway" href="/user_manual">
                                Panduan Lanjut Menggunakan <span className="ml-1 font-weight-bold mr-1">meniaga</span> (Peniaga & Pembeli) <div className="ml-3" style={{ transform: "rotate(270deg)" }}>{<ArrowDownwardIcon />}</div>
                            </a>
                            : <a className="red-button text-raleway" href="/user_manual">
                                How does it work (Sellers & Buyers) <div className="ml-3" style={{ transform: "rotate(270deg)" }}>{<ArrowDownwardIcon />}</div>
                            </a>
                        }
                    </div>
                </div>

            </div>
        </div>
    );
}

function MobileView() {
    return (
        <div className="row third-mobile">
            <div style={{ position: "relative" }}>
                <img src={MobileApp2} className="large-image" alt="mobile2" style={{ position: "absolute", top: 50, left: -20 }} />
                <img src={MobileApp1} className="small-image" alt="mobile1" style={{ position: "absolute", top: 270, left: 110 }} />
            </div>

            <div style={{ paddingTop: "700px" }}>
                <div className="text-center text-white text-raleway font-weight-bold mb-3" style={{ fontSize: "55px" }}>
                    {i18n.t('how')}
                </div>
                <div className="row text-raleway text-white" style={{ marginTop: "10px" }}>
                    <span className="circle-icon text-center mr-3 ml-3">
                        1
                    </span>
                    {i18n.language == 'my' ?
                    <span className="tip-message" style={{ width: "70%" }}>
                        Peniaga buka e-Gerai di app <span className="font-weight-bold font-italic">meniaga</span>
                    </span>
                    : <span className="tip-message" style={{ width: "70%" }}>
                        Merchants open e-Gerai in the <span className="font-weight-bold font-italic">meniaga</span> app
                    </span>
                    }
                </div>
                <div className="row text-raleway text-white" style={{ marginTop: "40px" }}>
                    <span className="circle-icon text-center mr-3 ml-3">
                        2
                    </span>
                    <span className="tip-message" style={{ width: "70%" }}>
                        {i18n.t('step2')}
                    </span>
                </div>
                <div className="row text-raleway text-white" style={{ marginTop: "10px" }}>
                    <span className="circle-icon text-center mr-3 ml-3">
                        3
                    </span>
                    {i18n.language == 'my' ?
                    <span className="tip-message" style={{ width: "70%" }}>
                        Pembeli buka app <span className="font-weight-bold font-italic">meniaga</span>
                    </span>
                    : <span className="tip-message" style={{ width: "70%" }}>
                        Buyer opens the <span className="font-weight-bold font-italic">meniaga</span> app
                    </span>
                    }
                </div>
                <div className="row text-raleway text-white" style={{ marginTop: "10px" }}>
                    <span className="circle-icon text-center mr-3 ml-3">
                        4
                    </span>
                    <span className="tip-message" style={{ width: "70%" }}>
                        {i18n.t('step4')}
                    </span>
                </div>
                <div className="row text-raleway text-white" style={{ marginTop: "65px" }}>
                    <span className="circle-icon text-center mr-3 ml-3">
                        5
                    </span>
                    <span className="tip-message" style={{ width: "70%" }}>
                        {i18n.t('step5')}
                    </span>
                </div>
                <div className="row text-raleway text-white" style={{ marginTop: "95px" }}>
                    <span className="circle-icon text-center mr-3 ml-3" style={{ lineHeight: "32px" }}>
                        6
                    </span>
                    <span className="tip-message" style={{ width: "70%" }}>
                        {i18n.t('step6')}
                    </span>
                </div>
                <div className="row" style={{ margin: "95px auto 0 auto" }}>
                { i18n.language == 'my' ? 
                    <a className="red-button text-raleway text-center" href="/user_manual">
                        <span>Panduan Lanjut Menggunakan <span className="ml-1 font-weight-bold mr-1">meniaga</span> (Peniaga & Pembeli) <div className="ml-3" style={{ transform: "rotate(270deg)" }}>{<ArrowDownwardIcon />}</div></span>
                    </a>
                    : <a className="red-button text-raleway text-center" href="/user_manual">
                                How does it work (Sellers & Buyers) <div className="ml-3" style={{ transform: "rotate(270deg)" }}>{<ArrowDownwardIcon />}</div>
                    </a>
                }
                </div>
            </div>
        </div>
    );
}

function ThirdPage() {
    return (
        <div>
            <div className="desktop-view">
                <DesktopView />
            </div>
            <div className="mobile-view">
                <MobileView />
            </div>
        </div>
    );
}

export default ThirdPage;