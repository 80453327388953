import React, {useState, useEffect} from 'react';
import ReactPixel from 'react-facebook-pixel';
import { createBrowserHistory } from 'history';
import { 
  BrowserRouter as Router, 
  Route, 
  Switch 
} from 'react-router-dom'; 
import DeepLinkingRedirect from './views/DeepLinkingRedirect';
import ReferralCodeAutoFilled from './views/ReferralCodeAutoFilled';
import Home from './views/Home';
import UserManual from './views/UserManual';
import Privacy from './views/Privacy';
import TermsAndConditions from './views/TermsAndConditions';
import AllianceForm from './views/AllianceForm';
import PaymentGatewayTermsAndConditions from './views/PaymentGatewayTermsAndConditions';
import FAQforPaymentAndDelivery from './views/FAQforPaymentAndDelivery';
import Footer from './views/Footer';

import '../src/i18n.config';
import i18n from '../src/i18n.config';

function App() {

  // const advancedMatching = { em: 'support@email.com' }; 
  const options = {
    autoConfig: true, 
    debug: false
  };
  ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID, null, options);
  // ReactPixel.revokeConsent();

  ReactPixel.pageView(); // For tracking page view
  // ReactPixel.track(event, data); // For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9
  // ReactPixel.trackSingle('PixelId', event, data); // For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9
  // ReactPixel.trackCustom(event, data); // For tracking custom events
  // ReactPixel.trackSingleCustom('PixelId', event, data);

  const history = createBrowserHistory();

  const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];

	if(path) {
		history.replace(path);
	}
  
  const [selected, setSelected] = useState(i18n.language == 'my' ? 'my' : 'en'); // If user change to preferred language then stick to that language
                                    
  const handleChange = (event) => {

    if (event.target.value == 'my') {
      i18n.changeLanguage('my')
      setSelected('my')
    }
    else if (event.target.value == 'en') {
      i18n.changeLanguage('en')
      setSelected('en')
    }
  }

  return (
    <Router>
      <Route exact path='/store/:id' component={DeepLinkingRedirect}></Route> 
      <Route exact path='/referral/:referral_code' component={ReferralCodeAutoFilled}></Route> 

      <div className="App">
        <Switch> 
          <Route exact path='/'>
            <Home 
              parentSelected={selected}
              parentSetSelected={setSelected}
              parentHandleChange={handleChange}
            />
          </Route> 
          <Route exact path='/user_manual' component={UserManual}></Route> 
          <Route exact path='/privacy' component={Privacy}></Route> 
          <Route exact path='/terms_conditions' component={TermsAndConditions}></Route> 
          <Route exact path='/payment_gateway_terms_conditions' component={PaymentGatewayTermsAndConditions}></Route> 
          <Route exact path='/faq_payment_delivery' component={FAQforPaymentAndDelivery}></Route> 
          <Route exact path='/alliance_form' component={AllianceForm}></Route> 
        </Switch> 
        <Footer visible={window.location.pathname !== "/alliance_form"}/> 
        {/* ORI. Footer for alliance form is disabled  */}
        {/* <Footer visible={window.location.pathname !== "/product_list"}/>  Disable footer for product_list (TESTING) */}
      </div>
    </Router>
  );
}

export default App;
