import React, { useState } from 'react';

import "../App.css";
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import AllianceBankLogo from "../assets/AllianceBank/Alliance Logo.png";
import MeniagaLogo from "../assets/AllianceBank/Meniaga Logo.png";
import i18n from '../i18n.config';


function DesktopView() {

    const [phoneNumber, setPhoneNumber] = useState("");
    const [isRegisterWithMeniaga, setIsRegisterWithMeniaga] = useState();
    const [isRegisterWithSSM, setIsRegisterWithSSM] = useState(i18n.language == 'my' ? "Tidak" : "No");
    const [email, setEmail] = useState("");
    const [fullName, setFullName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [companyNameInput, setCompanyNameInput] = useState(true);
    const [fullNameOnChanged, setFullNameOnChanged] = useState(false);
    const [EmailOnChanged, setEmailOnChanged] = useState(false);
    const [PhoneNumberOnChanged, setPhoneNumberOnChanged] = useState(false);
    const [IsRegisterWithSSMOnChanged, setIsRegisterWithSSMOnChanged] = useState(false);
    const [CompanyNameOnChanged, setCompanyNameOnChanged] = useState(false);

    function onChangePhoneNumber(value) {
        let newValue = value;

        if (value.charAt(0) == "0" || value.charAt(0) == "+") {
            newValue = 6 + value;
        }

        setPhoneNumber(newValue);
        setPhoneNumberOnChanged(true);
    }

    function onChangefullName(value) {
        setFullName(value);
        setFullNameOnChanged(true);
    }

    function onChangeEmail(value) {
        setEmail(value);
        setEmailOnChanged(true);
    }

    function onChangeIsRegisterWithSSM(value) {
        setIsRegisterWithSSM(value);
        setIsRegisterWithSSMOnChanged(true);
        setCompanyNameInput(false);

        if (value == "Tidak" || "No") {
            setCompanyNameInput(true);
            setCompanyName("");
        }
    }

    function onChangeCompanyName(value) {
        setCompanyName(value);
        setCompanyNameOnChanged(true);
    }

    function handleValidation() {
        let formIsValid = true;

        //Name
        if (!fullName) {
            setFullNameOnChanged(true);
            formIsValid = false;
        }

        //Email
        if (!email) {
            setEmailOnChanged(true);
            formIsValid = false;
        }

        //Phone Number
        if (!phoneNumber) {
            setPhoneNumberOnChanged(true);
            formIsValid = false;
        }

        //Company Name
        if (!companyName && !companyNameInput) {
            setCompanyNameOnChanged(true);
            formIsValid = false;
        }

        return formIsValid;
    }

    //submit to google sheet alliance
    function submitForm() {
        if (handleValidation()) {

            let url = 'https://sheetdb.io/api/v1/jjh6hdmx4niiw'

            let dataType = {
                "fullName": fullName,
                "phoneNumber": phoneNumber,
                "email": email,
                "isRegisteredwithMeniaga": isRegisterWithMeniaga ? isRegisterWithMeniaga : i18n.language == 'my' ? "Ya" : "Yes",
                "isRegisteredwithSSM": isRegisterWithSSM ? isRegisterWithSSM : i18n.language == 'my' ? "Ya" : "Yes",
                "companyName": companyName ? companyName : i18n.language == 'my' ? "Tiada" : 'Nothing',
                "Timestamp": new Date().toLocaleString() + ""
            };

            fetch(url, {
                crossDomain: true,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    data: dataType
                })
            })
                .then(response => response.json())
                .then(responseJson => {
                    i18n.language == 'my' ? alert("Permohonan anda telah dihantar") : alert("Your application has been submitted");
                    window.location.reload();
                })
        } else {
            i18n.language == 'my' ? alert("Sila Lengkapkan Maklumat Yang Tertera") : alert("Please complete the information provided");
        }
    }

    return (

        <div>
            {i18n.language == 'my' ?

                <div className="alliance" style={{ justifyContent: "space-between" }}>
                    <div style={{ display: "flex", flexDirection: "column", padding: "0 100px 30px 100px" }}>
                        <div className="row">
                            <div className="text-white circle-icon text-center" style={{ marginTop: 10, marginLeft: 40 }}>
                                <a href="/#alliance_page" style={{ color: "white" }}>{<ArrowBack />}</a>
                            </div>
                        </div>

                        <div style={{ textAlign: "center" }}>
                            <img src={AllianceBankLogo} className="alliance-image-mobile" alt="meniagaIcon" style={{ height: 180, paddingBottom: 10 }} />
                            <img src={MeniagaLogo} className="meniaga-image-mobile" alt="meniagaIcon" style={{ height: 40 }} />
                        </div>

                        <form id="alliance-form">
                            <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
                                <div className="col-md-6 text-blue-theme text-raleway" style={{ fontSize: "22px" }}>
                                    <p style={{ textAlign: "left", paddingLeft: "20%" }}>Nama penuh</p>
                                    <p style={{ textAlign: "left", paddingLeft: "20%" }}><i>(Seperti di dalam kad pengenalan)</i></p>
                                </div>
                                <div className="col-md-6 text-blue-theme text-raleway" style={{ fontSize: "22px" }}>
                                    <p>&nbsp;</p>
                                    <p style={{ textAlign: "left", paddingLeft: "20%" }}>E-mel</p>
                                </div>
                            </div>

                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div className="col-md-6" style={{ textAlign: "left", paddingLeft: "10%" }}>
                                    <input className="alliance_form" type="text" id="fullName" name="fullName" placeholder="Nama penuh*" onChange={(e) => onChangefullName(e.target.value)} /><br />
                                    <p className="text-red-theme">{fullNameOnChanged && fullName == "" ? "Nama tidak boleh kosong" : "\u00a0"}</p>
                                </div>
                                <div className="col-md-6" style={{ textAlign: "left", paddingLeft: "10%" }}>
                                    <input className="alliance_form" type="text" id="email" name="email" placeholder="Alamat Emel*" onChange={(e) => onChangeEmail(e.target.value)} /><br />
                                    <p className="text-red-theme">{EmailOnChanged && email == "" ? "Emel tidak boleh kosong" : "\u00a0"}</p>
                                </div>
                            </div>

                            <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
                                <div className="col-md-6 text-blue-theme text-raleway" style={{ fontSize: "22px" }}>
                                    <p style={{ textAlign: "left", paddingLeft: "20%" }}>No. telefon bimbit</p>
                                </div>
                                <div className="col-md-6 text-blue-theme text-raleway" style={{ fontSize: "22px" }}>
                                    <p style={{ textAlign: "left", paddingLeft: "20%" }}>Sudah mendaftar bersama <b><i>meniaga</i></b>?</p>
                                </div>
                            </div>

                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div className="col-md-6" style={{ textAlign: "left", paddingLeft: "10%" }}>
                                    <input className="alliance_form" type="number" id="phoneNumber" name="phoneNumber" placeholder="No. telefon bimbit*" onChange={(e) => onChangePhoneNumber(e.target.value)} /><br />
                                    <p className="text-red-theme">{PhoneNumberOnChanged && phoneNumber == "" ? "No. Telefon Bimbit tidak boleh kosong" : "\u00a0"}</p>
                                </div>
                                <div className="col-md-6" style={{ textAlign: "left", paddingLeft: "10%" }}>
                                    <select className="alliance_form" id="isRegisteredwithMeniaga" placeholder="Sila pilih" onChange={(e) => setIsRegisterWithMeniaga(e.target.value)}>
                                        <option value="Ya">Ya</option>
                                        <option value="Tidak">Tidak</option>
                                    </select>
                                </div>
                            </div>

                            <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
                                <div className="col-md-6 text-blue-theme text-raleway" style={{ fontSize: "22px" }}>
                                    <p style={{ textAlign: "left", paddingLeft: "20%" }}>Sudah mendaftar bersama <b><i>Suruhanjaya Syarikat Malaysia</i></b>?</p>
                                </div>
                                
                                <div className="col-md-6 text-blue-theme text-raleway" style={{ fontSize: "22px" }}>
                                    <p style={{ textAlign: "left", paddingLeft: "20%" }}>Sila nyatakan pendaftaran nama perniagaan</p>
                                </div>
                            </div>

                            <div style={{ display: "flex", flexDirection: "row" }}>
                            
                                <div className="col-md-6" style={{ textAlign: "left", paddingLeft: "10%" }}>
                                    <select className="alliance_form" id="isRegisteredwithSSM" placeholder="Sila pilih" onChange={(e) => onChangeIsRegisterWithSSM(e.target.value)} value={isRegisterWithSSM}>
                                        <option value="Ya">Ya</option>
                                        <option value="Tidak">Tidak</option>
                                    </select>
                                </div>
                            
                                <div className="col-md-6" style={{ textAlign: "left", paddingLeft: "10%" }}>
                                    <input className="alliance_form" type="text" id="companyName" name="companyName" placeholder="Nama perniagaan" disabled={companyNameInput}
                                        onChange={(e) => onChangeCompanyName(e.target.value)} value={companyName} /><br />
                                    <p className="text-red-theme">{CompanyNameOnChanged && !companyNameInput && companyName === "" ? "Nama perniagaan tidak boleh kosong" : "\u00a0"}</p>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="alliance-form-footer">
                        <div className="col-md-4">
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <p className="text-blue-theme medium-message mb-4 mr-3">
                                    <b>Daftar Sekarang</b>
                                </p>

                                <div className="text-white circle-icon text-center" style={{ cursor: "pointer" }} onClick={submitForm}>
                                    <span style={{ color: "white" }}>{<ArrowForward />}</span>
                                </div>
                            </div>

                            <p className="small-message-bold text-blue-theme" style={{ width: "80%" }}>
                                <i>Pihak kami akan menghubungi anda dalam masa terdekat selepas selesai mengisi borang ini</i>
                            </p>
                        </div>
                    </div>
                </div>
                : <div className="alliance" style={{ justifyContent: "space-between" }}>
                    <div style={{ display: "flex", flexDirection: "column", padding: "0 100px 30px 100px" }}>
                        <div className="row">
                            <div className="text-white circle-icon text-center" style={{ marginTop: 10, marginLeft: 40 }}>
                                <a href="/#alliance_page" style={{ color: "white" }}>{<ArrowBack />}</a>
                            </div>
                        </div>

                        <div style={{ textAlign: "center" }}>
                            <img src={AllianceBankLogo} className="alliance-image-mobile" alt="meniagaIcon" style={{ height: 180, paddingBottom: 10 }} />
                            <img src={MeniagaLogo} className="meniaga-image-mobile" alt="meniagaIcon" style={{ height: 40 }} />
                        </div>

                        <form id="alliance-form">
                            <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
                                <div className="col-md-6 text-blue-theme text-raleway" style={{ fontSize: "22px" }}>
                                    <p style={{ textAlign: "left", paddingLeft: "20%" }}>Full name</p>
                                    <p style={{ textAlign: "left", paddingLeft: "20%" }}><i>(As in I/C)</i></p>
                                </div>
                                <div className="col-md-6 text-blue-theme text-raleway" style={{ fontSize: "22px" }}>
                                    <p>&nbsp;</p>
                                    <p style={{ textAlign: "left", paddingLeft: "20%" }}>Email</p>
                                </div>
                            </div>

                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div className="col-md-6" style={{ textAlign: "left", paddingLeft: "10%" }}>
                                    <input className="alliance_form" type="text" id="fullName" name="fullName" placeholder="Full name*" onChange={(e) => onChangefullName(e.target.value)} /><br />
                                    <p className="text-red-theme">{fullNameOnChanged && fullName == "" ? "Name cannot be empty" : "\u00a0"}</p>
                                </div>
                                <div className="col-md-6" style={{ textAlign: "left", paddingLeft: "10%" }}>
                                    <input className="alliance_form" type="text" id="email" name="email" placeholder="Email Address*" onChange={(e) => onChangeEmail(e.target.value)} /><br />
                                    <p className="text-red-theme">{EmailOnChanged && email == "" ? "Email cannot be empty" : "\u00a0"}</p>
                                </div>
                            </div>

                            <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
                                <div className="col-md-6 text-blue-theme text-raleway" style={{ fontSize: "22px" }}>
                                    <p style={{ textAlign: "left", paddingLeft: "20%" }}>Phone number</p>
                                </div>
                                <div className="col-md-6 text-blue-theme text-raleway" style={{ fontSize: "22px" }}>
                                    <p style={{ textAlign: "left", paddingLeft: "20%" }}>Already registered on <b><i>meniaga</i></b>?</p>
                                </div>
                            </div>

                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div className="col-md-6" style={{ textAlign: "left", paddingLeft: "10%" }}>
                                    <input className="alliance_form" type="number" id="phoneNumber" name="phoneNumber" placeholder="Phone number*" onChange={(e) => onChangePhoneNumber(e.target.value)} /><br />
                                    <p className="text-red-theme">{PhoneNumberOnChanged && phoneNumber == "" ? "Phone number cannot be empty" : "\u00a0"}</p>
                                </div>
                                <div className="col-md-6" style={{ textAlign: "left", paddingLeft: "10%" }}>
                                    <select className="alliance_form" id="isRegisteredwithMeniaga" placeholder="Please choose" onChange={(e) => setIsRegisterWithMeniaga(e.target.value)}>
                                        <option value="Ya">Yes</option>
                                        <option value="Tidak">No</option>
                                    </select>
                                </div>
                            </div>

                            <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
                                <div className="col-md-6 text-blue-theme text-raleway" style={{ fontSize: "22px" }}>
                                    <p style={{ textAlign: "left", paddingLeft: "20%" }}>Already registered with the <b><i>Companies Commission of Malaysia (SSM)</i></b>?</p>
                                </div>
                                {/* new */}
                                <div className="col-md-6 text-blue-theme text-raleway" style={{ fontSize: "22px" }}>
                                    <p style={{ textAlign: "left", paddingLeft: "20%" }}>Please specify your business name registration</p>
                                </div>
                            </div>

                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div className="col-md-6" style={{ textAlign: "left", paddingLeft: "10%" }}>
                                    <select className="alliance_form" id="isRegisteredwithSSM" placeholder="Please choose" onChange={(e) => onChangeIsRegisterWithSSM(e.target.value)} value={isRegisterWithSSM}>
                                        <option value="Ya">Yes</option>
                                        <option value="Tidak">No</option>
                                    </select>
                                </div>
                            
                                <div className="col-md-6" style={{ textAlign: "left", paddingLeft: "10%" }}>
                                    <input className="alliance_form" type="text" id="companyName" name="companyName" placeholder="Business name" disabled={companyNameInput}
                                        onChange={(e) => onChangeCompanyName(e.target.value)} value={companyName} /><br />
                                    <p className="text-red-theme">{CompanyNameOnChanged && !companyNameInput && companyName === "" ? "Business name cannot be empty" : "\u00a0"}</p>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="alliance-form-footer">
                        <div className="col-md-4">
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <p className="text-blue-theme medium-message mb-4 mr-3">
                                    <b>Register Now</b>
                                </p>

                                <div className="text-white circle-icon text-center" style={{ cursor: "pointer" }} onClick={submitForm}>
                                    <span style={{ color: "white" }}>{<ArrowForward />}</span>
                                </div>
                            </div>

                            <p className="small-message-bold text-blue-theme" style={{ width: "80%" }}>
                                <i>Our team will contact you after completing this form</i>
                            </p>
                        </div>
                    </div>
                </div>
            }
        </div>

    );
}

/* MOBILE VIEW */
function MobileView() {

    const [phoneNumber, setPhoneNumber] = useState("");
    const [isRegisterWithMeniaga, setIsRegisterWithMeniaga] = useState();
    const [isRegisterWithSSM, setIsRegisterWithSSM] = useState(i18n.language == 'my' ? "Tidak" : "No");
    const [email, setEmail] = useState("");
    const [fullName, setFullName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [companyNameInput, setCompanyNameInput] = useState(true);
    const [fullNameOnChanged, setFullNameOnChanged] = useState(false);
    const [EmailOnChanged, setEmailOnChanged] = useState(false);
    const [PhoneNumberOnChanged, setPhoneNumberOnChanged] = useState(false);
    const [IsRegisterWithSSMOnChanged, setIsRegisterWithSSMOnChanged] = useState(false);
    const [CompanyNameOnChanged, setCompanyNameOnChanged] = useState(false);

    function onChangePhoneNumber(value) {
        let newValue = value;

        if (value.charAt(0) == "0" || value.charAt(0) == "+") {
            newValue = 6 + value;
        }

        setPhoneNumber(newValue);
        setPhoneNumberOnChanged(true);
    }

    function onChangefullName(value) {
        setFullName(value);
        setFullNameOnChanged(true);
    }

    function onChangeEmail(value) {
        setEmail(value);
        setEmailOnChanged(true);
    }

    function onChangeIsRegisterWithSSM(value) {
        setIsRegisterWithSSM(value);
        setIsRegisterWithSSMOnChanged(true);
        setCompanyNameInput(false);

        if (value == "Tidak" || "No") {
            setCompanyNameInput(true);
            setCompanyName("");
        }
    }

    function onChangeCompanyName(value) {
        setCompanyName(value);
        setCompanyNameOnChanged(true);
    }

    function handleValidation() {
        let formIsValid = true;

        //Name
        if (!fullName) {
            setFullNameOnChanged(true);
            formIsValid = false;
        }

        //Email
        if (!email) {
            setEmailOnChanged(true);
            formIsValid = false;
        }

        //Phone Number
        if (!phoneNumber) {
            setPhoneNumberOnChanged(true);
            formIsValid = false;
        }

        //Company Name
        if (!companyName && !companyNameInput) {
            setCompanyNameOnChanged(true);
            formIsValid = false;
        }

        return formIsValid;
    }

    //submit to google sheet alliance
    function submitForm() {
        if (handleValidation()) {

            let url = 'https://sheetdb.io/api/v1/jjh6hdmx4niiw'

            let dataType = {
                "fullName": fullName,
                "phoneNumber": phoneNumber,
                "email": email,
                "isRegisteredwithMeniaga": isRegisterWithMeniaga ? isRegisterWithMeniaga : i18n.language == 'my' ? "Ya" : "Yes",
                "isRegisteredwithSSM": isRegisterWithSSM ? isRegisterWithSSM : i18n.language == 'my' ? "Ya" : "Yes",
                "Timestamp": new Date().toLocaleString() + ""
            };

            fetch(url, {
                crossDomain: true,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    data: dataType
                })
            })
                .then(response => response.json())
                .then(responseJson => {
                    i18n.language == 'my' ? alert("Permohonan anda telah dihantar") : alert("Your application has been submitted");
                    window.location.reload();
                })
        } else {
            i18n.language == 'my' ? alert("Sila Lengkapkan Maklumat Yang Tertera") : alert('Please complete the information provided');
        }
    }

    // Copy from desktop view but make sure styling is the mobile view styling
    return (
        <div>
            {i18n.language == 'my' ?

                <div className="alliance" style={{ justifyContent: "space-between" }}>


                    <div style={{ display: "flex", flexDirection: "column", padding: "0 50px 30px 50px" }}>
                        <div className="row">
                            <div className="text-white circle-icon text-center" style={{ marginTop: 40, marginLeft: 40 }}>
                                <a href="/#alliance_page" style={{ color: "white" }}>{<ArrowBack />}</a>
                            </div>
                        </div>

                        <div style={{ marginTop: 20, textAlign: "center" }}>
                            <img src={AllianceBankLogo} className="alliance-image-mobile" alt="meniagaIcon" style={{ paddingBottom: 5 }} />
                            <img src={MeniagaLogo} className="meniaga-image-mobile" alt="meniagaIcon" />
                        </div>

                        <form id="alliance-form">
                            <div style={{ display: "flex", flexDirection: "column", marginTop: 30 }}>
                                <div className="col-md-6 text-blue-theme text-raleway mb-3" style={{ fontSize: "22px" }}>
                                    <p style={{ textAlign: "left" }}>Nama penuh</p>
                                    <p style={{ textAlign: "left", fontSize: "18px" }}><i>(Seperti di dalam kad pengenalan)</i></p>
                                </div>
                                <div className="col-md-6" style={{ textAlign: "left" }}>
                                    <input className="alliance_form" type="text" id="fullName" name="fullName" placeholder="Nama penuh" onChange={(e) => onChangefullName(e.target.value)} /><br />
                                    <p className="text-red-theme">{fullNameOnChanged && fullName == "" ? "Nama tidak boleh kosong" : "\u00a0"}</p>
                                </div>
                            </div>

                            <div style={{ display: "flex", flexDirection: "column", marginTop: 30 }}>
                                <div className="col-md-6 text-blue-theme text-raleway mb-3" style={{ fontSize: "22px" }}>
                                    <p style={{ textAlign: "left" }}>E-mel</p>
                                </div>
                                <div className="col-md-6" style={{ textAlign: "left" }}>
                                    <input className="alliance_form" type="text" id="email" name="email" placeholder="Alamat Emel*" onChange={(e) => onChangeEmail(e.target.value)} /><br />
                                    <p className="text-red-theme">{EmailOnChanged && email == "" ? "Emel tidak boleh kosong" : "\u00a0"}</p>
                                </div>
                            </div>


                            <div style={{ display: "flex", flexDirection: "column", marginTop: 30 }}>
                                <div className="col-md-6 text-blue-theme text-raleway mb-3" style={{ fontSize: "22px" }}>
                                    <p style={{ textAlign: "left" }}>No. telefon bimbit</p>
                                </div>
                                <div className="col-md-6" style={{ textAlign: "left" }}>
                                    <input className="alliance_form" type="text" id="phoneNumber" name="phoneNumber" placeholder="No. telefon bimbit*" onChange={(e) => onChangePhoneNumber(e.target.value)} /><br />
                                    <p className="text-red-theme">{PhoneNumberOnChanged && phoneNumber == "" ? "No. Telefon Bimbit tidak boleh kosong" : "\u00a0"}</p>
                                </div>

                            </div>

                            <div style={{ display: "flex", flexDirection: "column", marginTop: 30 }}>
                                <div className="col-md-6 text-blue-theme text-raleway mb-3" style={{ fontSize: "22px" }}>
                                    <p style={{ textAlign: "left" }}>Sudah mendaftar bersama <b><i>meniaga</i></b>?</p>
                                </div>
                                <div className="col-md-6" style={{ textAlign: "left", marginTop: 0 }}>
                                    <select className="alliance_form" id="isRegisteredwithMeniaga" onChange={(e) => setIsRegisterWithMeniaga(e.target.value)}>
                                        <option value="Ya">Ya</option>
                                        <option value="Tidak">Tidak</option>
                                    </select>
                                </div>
                            </div>

                            <div style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
                                <div className="col-md-6 text-blue-theme text-raleway mb-3" style={{ fontSize: "22px" }}>
                                    <p style={{ textAlign: "left" }}>Sudah mendaftar bersama <b><i>Suruhanjaya Syarikat Malaysia</i></b>?</p>
                                </div>
                                <div className="col-md-6" style={{ textAlign: "left", marginTop: 0 }}>
                                    <select className="alliance_form" id="isRegisteredwithSSM" onChange={(e) => setIsRegisterWithSSM(e.target.value)}>
                                        <option value="Ya">Ya</option>
                                        <option value="Tidak">Tidak</option>
                                    </select>
                                </div>
                            </div>

                            
                            <div style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
                                <div className="col-md-6 text-blue-theme text-raleway" style={{ fontSize: "22px" }}>
                                    <p style={{ textAlign: "left", paddingLeft: "20%" }}>Sila nyatakan pendaftaran nama perniagaan</p>
                                </div>
                                <div className="col-md-6" style={{ textAlign: "left" }}>
                                    <input className="alliance_form" type="text" id="companyName" name="companyName" placeholder="Nama perniagaan" disabled={companyNameInput}
                                        onChange={(e) => onChangeCompanyName(e.target.value)} value={companyName} /><br />
                                    <p className="text-red-theme">{CompanyNameOnChanged && !companyNameInput && companyName === "" ? "Nama perniagaan tidak boleh kosong" : "\u00a0"}</p>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="alliance-form-footer">
                        <div className="col-md-4">
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <p className="text-blue-theme medium-message mb-4 mr-3">
                                    <b>Daftar Sekarang</b>
                                </p>

                                <div className="text-white circle-icon text-center" style={{ cursor: "pointer" }} onClick={submitForm}>
                                    <span style={{ color: "white" }}>{<ArrowForward />}</span>
                                </div>
                            </div>

                            <p className="small-message-bold text-blue-theme" style={{ textAlign: "left" }}>
                                <i>Pihak kami akan menghubungi anda dalam masa terdekat selepas selesai mengisi borang ini</i>
                            </p>
                        </div>
                    </div>

                </div>

                : <div className="alliance" style={{ justifyContent: "space-between" }}>


                    <div style={{ display: "flex", flexDirection: "column", padding: "0 50px 30px 50px" }}>
                        <div className="row">
                            <div className="text-white circle-icon text-center" style={{ marginTop: 40, marginLeft: 40 }}>
                                <a href="/#alliance_page" style={{ color: "white" }}>{<ArrowBack />}</a>
                            </div>
                        </div>

                        <div style={{ marginTop: 20, textAlign: "center" }}>
                            <img src={AllianceBankLogo} className="alliance-image-mobile" alt="meniagaIcon" style={{ paddingBottom: 5 }} />
                            <img src={MeniagaLogo} className="meniaga-image-mobile" alt="meniagaIcon" />
                        </div>

                        <form id="alliance-form">
                            <div style={{ display: "flex", flexDirection: "column", marginTop: 30 }}>
                                <div className="col-md-6 text-blue-theme text-raleway mb-3" style={{ fontSize: "22px" }}>
                                    <p style={{ textAlign: "left" }}>Full name</p>
                                    <p style={{ textAlign: "left", fontSize: "18px" }}><i>(As in I/C)</i></p>
                                </div>
                                <div className="col-md-6" style={{ textAlign: "left" }}>
                                    <input className="alliance_form" type="text" id="fullName" name="fullName" placeholder="Full name" onChange={(e) => onChangefullName(e.target.value)} /><br />
                                    <p className="text-red-theme">{fullNameOnChanged && fullName == "" ? "Name cannot be empty" : "\u00a0"}</p>
                                </div>
                            </div>

                            <div style={{ display: "flex", flexDirection: "column", marginTop: 30 }}>
                                <div className="col-md-6 text-blue-theme text-raleway mb-3" style={{ fontSize: "22px" }}>
                                    <p style={{ textAlign: "left" }}>Email</p>
                                </div>
                                <div className="col-md-6" style={{ textAlign: "left" }}>
                                    <input className="alliance_form" type="text" id="email" name="email" placeholder="Email Address*" onChange={(e) => onChangeEmail(e.target.value)} /><br />
                                    <p className="text-red-theme">{EmailOnChanged && email == "" ? "Email cannot be empty" : "\u00a0"}</p>
                                </div>
                            </div>


                            <div style={{ display: "flex", flexDirection: "column", marginTop: 30 }}>
                                <div className="col-md-6 text-blue-theme text-raleway mb-3" style={{ fontSize: "22px" }}>
                                    <p style={{ textAlign: "left" }}>Phone number</p>
                                </div>
                                <div className="col-md-6" style={{ textAlign: "left" }}>
                                    <input className="alliance_form" type="text" id="phoneNumber" name="phoneNumber" placeholder="Phone number*" onChange={(e) => onChangePhoneNumber(e.target.value)} /><br />
                                    <p className="text-red-theme">{PhoneNumberOnChanged && phoneNumber == "" ? "Phone number cannot be empty" : "\u00a0"}</p>
                                </div>

                            </div>

                            <div style={{ display: "flex", flexDirection: "column", marginTop: 30 }}>
                                <div className="col-md-6 text-blue-theme text-raleway mb-3" style={{ fontSize: "22px" }}>
                                    <p style={{ textAlign: "left" }}>Already registered on <b><i>meniaga</i></b>?</p>
                                </div>
                                <div className="col-md-6" style={{ textAlign: "left", marginTop: 0 }}>
                                    <select className="alliance_form" id="isRegisteredwithMeniaga" onChange={(e) => setIsRegisterWithMeniaga(e.target.value)}>
                                        <option value="Ya">Yes</option>
                                        <option value="Tidak">No</option>
                                    </select>
                                </div>
                            </div>

                            <div style={{ display: "flex", flexDirection: "column", marginTop: 30 }}>
                                <div className="col-md-6 text-blue-theme text-raleway mb-3" style={{ fontSize: "22px" }}>
                                    <p style={{ textAlign: "left" }}>Already registered with the <b><i>Companies Commission of Malaysia (SSM)</i></b>?</p>
                                </div>
                                <div className="col-md-6" style={{ textAlign: "left", marginTop: 0 }}>
                                    <select className="alliance_form" id="onChangeisRegisteredwithSSM" onChange={(e) => isRegisterWithSSM(e.target.value)}>
                                        <option value="Ya">Yes</option>
                                        <option value="Tidak">No</option>
                                    </select>
                                </div>
                            </div>

                            <div style={{ display:"flex", flexDirection:"column", marginTop: 10}}>
                        <div className ="col-md-6 text-blue-theme text-raleway" style={{ fontSize:"22px"}}>
                            <p style={{textAlign:"left"}}>Please specify your business name registration </p>
                        </div>
                        <div className ="col-md-6" style={{ textAlign:"left" }}>
                            <input className="alliance_form" type="text" id="companyName" name="companyName" placeholder="Business name" disabled={companyNameInput}
                                onChange={(e)=> onChangeCompanyName(e.target.value)} value={companyName}/><br/>
                            <p className="text-red-theme">{ CompanyNameOnChanged && !companyNameInput && companyName === "" ? "Company name cannot be empty" : "\u00a0"}</p>
                        </div>                       
                    </div>

                        </form>
                    </div>

                    <div className="alliance-form-footer">
                        <div className="col-md-4">
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <p className="text-blue-theme medium-message mb-4 mr-3">
                                    <b>Register Now</b>
                                </p>

                                <div className="text-white circle-icon text-center" style={{ cursor: "pointer" }} onClick={submitForm}>
                                    <span style={{ color: "white" }}>{<ArrowForward />}</span>
                                </div>
                            </div>

                            <p className="small-message-bold text-blue-theme" style={{ textAlign: "left" }}>
                                <i>Our team will contact you after completing this form</i>
                            </p>
                        </div>
                    </div>

                </div>
            }

        </div>
    );
}


function AllianceForm() {
    return (
        <div>
            <div className="desktop-view">
                <DesktopView />
            </div>
            <div className="mobile-view">
                <MobileView />
            </div>
        </div>
    );
}

export default AllianceForm;