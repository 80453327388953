import React, { useEffect, useRef, useState } from 'react'
import "../App.css";
import i18n from '../i18n.config';
import RamadhanBanner from '../assets/ramadhan.png';
import RamadhanBannerMobile from '../assets/raya-mobile-bg.png';
import RamadhanImage from '../assets/raya-assets.png';
import { isAndroid, isIOS } from 'react-device-detect';

const DesktopView = ({ parentSelected, parentSetSelected, parentHandleChange, appStore }) => {
	return (
		<div 
			id="ramadhan-page" 
			className='ramadhan' 
			style={{
				// flex:1,
				backgroundImage: `url(${RamadhanBanner})`, 
				backgroundRepeat:'no-repeat', 
				backgroundSize:'cover',
				flexDirection:'row'
			}}
		>
			{/* divider */}
			<div style={{width:'50%'}}/>

			<div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'flex-end', textAlign:'end', width:'50%', paddingTop:50, paddingRight:80, gap:50}}>
				<div style={{display:'flex', flexDirection:'column', gap:30}}>
					<div style={{display:'flex', flexDirection:'column', gap:28}}>
						<div className="text-dark-gray-theme sub-medium-message">{i18n.t('kalutRayaKalut')}<span className="text-pink-theme text-italic">meniaga</span></div>
						<div>
							<div className='text-dark-gray-theme' style={{fontSize:18.5, fontWeight:400, wordSpacing:3, fontFamily:'Inter Tight, sans-serif'}}>{i18n.t('bulanYangDitunggu1')}</div>
							<div className='text-dark-gray-theme' style={{fontSize:18.5, fontWeight:400, wordSpacing:3, fontFamily:'Inter Tight, sans-serif'}}>{i18n.t('bulanYangDitunggu2')}</div>
						</div>
					</div>

					<div style={{display:'flex', flexDirection:'column', alignItems:'flex-end', gap:10}}>
						<div style={{display:'flex', flexDirection:'row', gap:10}}>
							<div className='text-dark-gray-theme chip'>{i18n.t('lebihBanyakKedai')}</div>
							<div className='text-dark-gray-theme chip'>{i18n.t('lebihBanyakPilihan')}</div>
						</div>

						<div style={{display:'flex'}}>
							<div className='text-dark-gray-theme chip'>{i18n.t('lebihBanyakPulangan')}</div>
						</div>
					</div>
				</div>

				<div style={{width: "175px"}}>
					<div style={{backgroundColor:'#00415E', textAlign:'center', borderRadius:5}}>
							<a id="sertai-button" className="button-link" style={{padding:'10px 14px 10px 14px', color:'#F7F9FA', fontFamily:'Inter Tight, sans-serif', fontWeight:'bold'}} target="_blank" rel="noopener noreferrer" href={appStore}>
								{i18n.t('sertaiMeniaga')}
							</a>
					</div>
				</div>
			</div>
		</div>
	)
}

const MobileView = ({ parentSelected, parentSetSelected, parentHandleChange, appStore }) => {
	return (
		<div 
			id="ramadhan-page" 
			className='ramadhan' 
			style={{
				// flex:1,
				backgroundImage: `url(${RamadhanBannerMobile})`, 
				backgroundRepeat:'no-repeat', 
				backgroundSize:'cover',
				flexDirection:'row',
				paddingTop:80,
				paddingBottom:60
			}}
		>
			{/* divider */}
			{/* <div style={{width:'50%'}}/> */}

			<div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'flex-end', textAlign:'end'}}>
				<div style={{display:'flex', flexDirection:'column', gap:30}}>
					<div style={{display:'flex', flexDirection:'column', gap:28}}>
						<div style={{display:'flex', flexDirection:'column', textAlign:'center', gap:20}}>
							<div className="text-dark-gray-theme sub-medium-message-mobile">{i18n.t('kalutRayaKalut')}</div>
							<span className="text-pink-theme text-italic sub-medium-message-mobile">meniaga</span>
						</div>
						<div style={{textAlign:'center', paddingLeft:40, paddingRight:40}}>
							<div className='text-dark-gray-theme' style={{fontSize:16.5, fontWeight:400, wordSpacing:3, fontFamily:'Inter Tight, sans-serif'}}>{i18n.t('bulanYangDitunggu1') + i18n.t('bulanYangDitunggu2')}</div>
						</div>
					</div>

					<div style={{display:'flex', flexDirection:'column', alignItems:'center', gap:10}}>
						<div style={{display:'flex', flexDirection:'row', gap:10}}>
							<div className='text-dark-gray-theme chip'>{i18n.t('lebihBanyakKedai')}</div>
							<div className='text-dark-gray-theme chip'>{i18n.t('lebihBanyakPilihan')}</div>
						</div>

						<div style={{display:'flex'}}>
							<div className='text-dark-gray-theme chip'>{i18n.t('lebihBanyakPulangan')}</div>
						</div>

						<div style={{display:'flex', flexDirection:'column', alignItems:'center', marginTop:24}}>
							<img src={RamadhanImage} alt={"Ramadhan Image"} style={{width:250, height:150, marginBottom:42}}/>
							<div style={{width:175}}>
								<div style={{backgroundColor:'#00415E', textAlign:'center', borderRadius:5}}>
									<a id="sertai-button" className="button-link" style={{padding:'10px 14px 10px 14px', color:'#F7F9FA', fontFamily:'Inter Tight, sans-serif', fontWeight:'bold'}} target="_blank" rel="noopener noreferrer" href={appStore}>{i18n.t('sertaiMeniaga')}</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

const RamadhanPage = ({ parentSelected, parentSetSelected, parentHandleChange }) => {
	const [appStore, setAppStore] = useState("");

	useEffect(() => {
		if(isAndroid) {
			setAppStore("https://play.google.com/store/apps/details?id=com.invoke.meniaga.my")
		} else if(isIOS) {
			setAppStore("https://apps.apple.com/my/app/meniaga-my/id1516839999")
		} else {
			setAppStore("https://play.google.com/store/apps/details?id=com.invoke.meniaga.my")
		}
	}, [])

	return (
        <div>
            <div className="desktop-view">
                <DesktopView
                    parentSelected={parentSelected}
                    parentSetSelected={parentSetSelected}
                    parentHandleChange={parentHandleChange}
					appStore={appStore}
                />
            </div>
            <div className="mobile-view">
                <MobileView
                    parentSelected={parentSelected}
                    parentSetSelected={parentSetSelected}
                    parentHandleChange={parentHandleChange}
					appStore={appStore}
                />
            </div>
        </div>
	)
}

export default RamadhanPage