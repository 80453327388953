import React from 'react';

import "../App.css";

import i18n from '../i18n.config';
 
function DesktopView() {
    return (
        <div className="second">    
            <div className="row h-40" style={{ paddingTop: "80px" }}>
                <div className="col-md-7 red-banner text-white">
                    <span className="text-white banner-message font-weight-bold">{i18n.t('zeroPercentCharge')}</span>
                </div>
                <div className="col-md-5" style={{ display: "flex", justifyContent: "center" }}>
                    { i18n.language == 'my' ? 
                    <p className="small-message text-blue-theme" style={{ width: "280px", margin: "auto", lineHeight: "1.8em" }}>
                        Tidak seperti platform lain, <span className="font-weight-bold font-italic">meniaga</span> <span className="text-red-theme">tidak mengenakan caj</span> kepada peniaga atau pembeli
                    </p>
                    : <p className="small-message text-blue-theme" style={{ width: "280px", margin: "auto", lineHeight: "1.8em" }}>
                    Unlike other platforms, <span className="font-weight-bold font-italic">meniaga</span> <span className="text-red-theme">does not charge a fee</span> to the merchants
                    </p>
                    }
                </div>
            </div>
            <div className="row h-60">
                <div className="col-md-12 text-center">
                    <div className="text-blue-theme text-raleway text-center" style={{ fontSize: "55px", marginTop: "100px", marginBottom: "20px" }}>
                        {i18n.t('why')} <span className="font-weight-bold font-italic">meniaga?</span>
                    </div>
                    <div className="row">
                        <div style={{ width: "5%" }} />
                        <div style={{ width: "25%" }}>
                            <p className="small-message text-blue-theme">
                                {i18n.t('easySearchSellerBuyer')}
                            </p>
                        </div>
                        <div className="row" style={{ width: "10%", paddingTop: "20px" }}>
                            <div style={{ borderRight: "1px solid #00334C", height: "50%", width: "50%" }} />
                            <div style={{ width: "50%", height: "50%" }} />
                        </div>
                        <div style={{ width: "25%" }}>
                            <p className="small-message text-blue-theme">
                                {i18n.t('additionalIncomeOpportunity')} 
                            </p>
                        </div>
                        <div className="row" style={{ width: "10%", paddingTop: "20px" }}>
                            <div style={{ borderRight: "1px solid #00334C", height: "50%", width: "50%" }} />
                            <div style={{ width: "50%", height: "50%" }} />
                        </div>
                        <div style={{ width: "25%" }}>
                            <p className="small-message text-blue-theme">
                                {i18n.t('helpOnlineBooking')} 
                            </p>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    );
}

function MobileView() {
    return (
        <div className="second">
            <div className="row h-40" style={{ paddingTop: "80px" }}>
                <div className="col-md-7 red-banner-mobile text-white">
                    <span className="text-white banner-message-mobile font-weight-bold">{i18n.t('zeroPercentCharge')}</span>
                </div>
                { i18n.language == 'my' ? 
                    <p className="mt-3 small-message text-blue-theme text-center" style={{ width: "280px", margin: "auto", lineHeight: "1.8em" }}>
                        Tidak seperti platform lain, <span className="font-weight-bold font-italic">meniaga</span> <span className="text-red-theme">tidak mengenakan caj</span> kepada peniaga atau pembeli
                    </p>
                    : <p className="mt-3 small-message text-blue-theme text-center" style={{ width: "280px", margin: "auto", lineHeight: "1.8em" }}>
                        Unlike other platforms, <span className="font-weight-bold font-italic">meniaga</span> <span className="text-red-theme">does not charge a fee</span> to the merchants
                    </p>
                }
            </div>
            <div className="row h-60 text-center" style={{ padding: "0 50px 0 50px" }}>
                <div className="mt-5 mb-3 text-blue-theme text-raleway text-center" style={{ fontSize: "50px" }}>
                    {i18n.t('why')} <span className="font-weight-bold font-italic">meniaga?</span>
                </div>
                <p className="small-message text-blue-theme">
                    {i18n.t('easySearchSellerBuyer')}
                </p>
                <div style={{ borderBottom: "1px solid #00334C", paddingBottom: "5px", width: "50%", margin: "auto" }} />
                <p className="small-message text-blue-theme" style={{ paddingTop: "15px" }}>
                    {i18n.t('additionalIncomeOpportunity')}  
                </p>
                <div style={{ borderBottom: "1px solid #00334C", paddingBottom: "5px", width: "50%", margin: "auto" }} />
                <p className="small-message text-blue-theme" style={{ paddingTop: "15px" }}>
                    {i18n.t('helpOnlineBooking')} 
                </p>
            </div>
        </div>  
    );
}

function SecondPage() {
    return (
        <div>
            <div className="desktop-view">
                <DesktopView />
            </div>
            <div className="mobile-view">   
                <MobileView />
            </div>
        </div>
    );
}

export default SecondPage;
