import React from 'react'

import MeniagaLight from "../assets/meniaga-icon-lightmode.png";
import Raya from "../assets/icon/bulan-bintang.png";

import { MenuItem, Select, makeStyles } from '@material-ui/core';

import "../App.css";
import i18n from '../i18n.config';

const Header = ({ parentSelected, parentSetSelected, parentHandleChange }) => {
	const languages = [
    {
        code: 'my',
        name: 'Bahasa Melayu'
    },
    {
        code: 'en',
        name: 'English'
    }
	]

	const useStyles = makeStyles({
		select: {
				"& .MuiSvgIcon-root": {
						color: "#3A3A3A",
						marginRight:6
				},
				width: 165,
				height: 40,
				backgroundColor: 'white',
				paddingLeft: 14,
				border: 'solid',
				borderWidth: 1,
				borderRadius: 8,
				borderColor: '#8A8A8A',
				color: '#3A3A3A',
				fontFamily: 'Raleway',
				fontSize: 15,
				fontWeight: 'bold',
				marginLeft: 70,
		},
	});

	const classes = useStyles();

	const DesktopView = ({ parentSelected, parentSetSelected, parentHandleChange }) => {
		return(
			<div className='sticky'>
				<div className="raya-info-banner">
						<img src={Raya} alt={"raya1"}/>&nbsp;
						<span style={{fontWeight:'bold', wordSpacing:1}}>{i18n.t('meriahkanRamadhan')}</span>&nbsp;
						<span style={{wordSpacing:1}}>{i18n.t('barangTersedia')}</span>&nbsp;
						<a rel="noopener noreferrer" href="#ramadhan-section" style={{fontWeight:'bold', color:'white', wordSpacing:1, textDecoration:'underline'}} >{i18n.t('ketahuiLanjut')}</a>
				</div>
				<div className="row header top-logo-section">
						<div style={{ width: "180px" }}>
								<img src={MeniagaLight} className="meniaga-icon-top" alt="meniagaIcon" />
						</div>

						<div>
								{/* WORKING */}
								{/* <select className='small text-raleway text-white p-2 font-weight-bold' style={{ backgroundColor: '#00334C', borderRadius: 5 }} value={parentSelected} onChange={parentHandleChange}>
										{languages.map((lng) => (
												<option value={lng.code}>{lng.name}</option>
										))}
								</select> */}

								<Select
										disableUnderline
										value={parentSelected}
										onChange={parentHandleChange}
										displayEmpty
										className={classes.select}
								>
										{languages.map((lng) => (
												<MenuItem value={lng.code}>{lng.name}</MenuItem>
										))}
								</Select>
						</div>
				</div>
			</div>
	)}

	const MobileView = ({ parentSelected, parentSetSelected, parentHandleChange }) => {
		return(
			<div className='sticky'>
				<div className="raya-info-banner raya-info-banner-mobile">
					<img src={Raya} alt={"raya1"}/>&nbsp;
					<div className={"raya-info-text-mobile"}>
						<span style={{fontWeight:'bold', wordSpacing:1}}>{i18n.t('meriahkanRamadhan')}</span>&nbsp;
						<span style={{wordSpacing:1}}>{i18n.t('barangTersedia')}</span>&nbsp;
						<a rel="noopener noreferrer" href="#ramadhan-section" style={{fontWeight:'bold', color:'white', wordSpacing:1, textDecoration:'underline'}} >{i18n.t('ketahuiLanjut')}</a>
					</div>
				</div>
				<div className="header top-logo-section top-logo-section-mobile">
					<div style={{ width: "100px" }}>
							<img src={MeniagaLight} className="meniaga-icon-top" alt="meniagaIcon" />
					</div>

					<div>
						<Select
								disableUnderline
								value={parentSelected}
								onChange={parentHandleChange}
								displayEmpty
								className={classes.select}
						>
								{languages.map((lng) => (
										<MenuItem value={lng.code}>{lng.name}</MenuItem>
								))}
						</Select>
					</div>
				</div>
			</div>
	)}

	return (

		<div>
			<div className="desktop-view">
				<DesktopView
					parentSelected={parentSelected}
					parentSetSelected={parentSetSelected}
					parentHandleChange={parentHandleChange}
				/>
			</div>
			<div className="mobile-view">
				<MobileView
					parentSelected={parentSelected}
					parentSetSelected={parentSetSelected}
					parentHandleChange={parentHandleChange}
				/>
			</div>
		</div>
	)
}

export default Header