import React from 'react';

function DesktopView() {
    return (
        
        <div className="fourth">    
            <div className="row h-30" style={{ paddingTop: "50px" }}>
                <div className="col-md-12">
                    <div className="text-blue-theme text-raleway" style={{ fontSize: "55px", marginBottom: "20px" }}>
                        Soalan Lazim (FAQ) berkenaan pembayaran
                    </div>
                </div>
            </div>
            <div className="row h-70 pt-3">
                <div className="col-md-6" style={{ paddingRight: "100px" }}>
                    <p className="text-raleway small-message" style={{ lineHeight: "1.6em" }}>
                        <span className="question">Apakah kaedah pembayaran yang tersedia di meniaga?</span><br/>
                        <span className="answer">Pembeli boleh membayar menggunakan kaedah Cash-On-Delivery (COD) ataupun dengan pembayaran secara online (FPX) menerusi payment gateway yang disediakan.</span><br/>
                        <br/>
                        <span className="question">Apa itu payment gateway (gerbang pembayaran)?</span><br/>
                        <span className="answer">Payment gateway ataupun gerbang pembayaran adalah pihak yang menghubungkan pembeli, peniaga dan bank di mana proses pembayaran boleh dibuat secara dalam talian.</span><br/>
                        <br/>
                        <span className="question">Sebagai seorang peniaga, apakah anggaran tempoh pemprosesan bayaran kepada saya daripada transaksi di aplikasi meniaga??</span><br/>
                        <span className="answer">Pihak kami memerlukan 4-10 hari bekerja bagi tujuan pemprosesan pembayaran kepada akaun bank peniaga masing-masing.</span><br/>             
                        <br/>
                    </p>
                </div>
                <div className="col-md-6" style={{ paddingRight: "100px" }}>
                    <p className="text-raleway small-message" style={{ lineHeight: "1.6em" }}>
                        <span className="question">Apakah senarai bank yang boleh digunakan di aplikasi meniaga bagi tujuan pembayaran?</span><br />
                        <span className="answer">Semua jenis bank yang ada di Malaysia boleh digunakan semasa membuat pembayaran secara atas talian.</span><br/>
                        <br/>
                        <span className="question">Boleh ke saya buat pembayaran menggunakan Kad Kredit?</span><br />
                        <span className="answer">Buat masa sekarang , hanya kaedah secara FPX ditawarkan. Kami akan maklumkan dari masa ke masa sekiranya terdapat pengumuman terbaru.</span><br/>
                        <br/>
                        <span className="question">Adakah saya akan dicaj jika saya memilih untuk buat pembayaran menggunakan payment gateway?</span><br />
                        <span className="answer">Ya. Caj akan dikenakan kepada pembeli.</span>         
                    </p>        
                </div>
            </div>  
            <div className="row h-30" style={{ paddingTop: "50px" }}>
                <div className="col-md-12">
                    <div className="text-blue-theme text-raleway" style={{ fontSize: "55px", marginBottom: "20px" }}>
                        Soalan Lazim (FAQ) berkenaan penghantaran
                    </div>
                </div>
            </div>
            <div className="row h-70 pt-3">
                <div className="col-md-6" style={{ paddingRight: "100px" }}>
                    <p className="text-raleway small-message" style={{ lineHeight: "1.6em" }}>
                        <span className="question">Apakah kaedah penghantaran yang ada di aplikasi meniaga?</span><br/>
                        <span className="answer">Peniaga boleh memilih sama ada mahu buat penghantaran sendiri ataupun menggunakan perkhidmatan Bungkus-It dan Lalamove sebagai rider untuk menghantar produk anda kepada pembeli.</span><br/>
                        <br/>
                        <span className="question">Berapakah kadar pembayaran untuk menggunakan servis penghantaran Bungkus-It dan Lalamove?</span><br/>
                        <span className="answer">Kadar pembayaran untuk menggunakan servis penghantaran yang ada di meniaga tertakluk kepada kadar-kadar yang telah ditetapkan oleh Bungkus-It dan Lalamove sendiri.</span><br/>
                        <br/>
                    </p>
                </div>
                <div className="col-md-6" style={{ paddingRight: "100px" }}>
                    <p className="text-raleway small-message" style={{ lineHeight: "1.6em" }}>
                        <span className="question">Jika saya memilih untuk buat penghantaran sendiri, berapa yang patut saya caj kepada pembeli?</span><br/>
                        <span className="answer">Penjual bebas untuk meletakkan sendiri harga caj kepada pembeli untuk khidmat penghantaran.</span><br/>             
                        <br/>
                        <span className="question">Apakah radius penghantaran di meniaga sekarang?</span><br />
                        <span className="answer">Buat masa ini, meniaga hanya beroperasi dalam radius 10km dari tempat pembeli ataupun penjual.</span><br/>
                        <br/>
                        <span className="question">Kalau cuaca hujan lebat, adakah pembeli masih boleh memilih untuk membuat pesanan untuk dihantar kepada pembeli?</span><br />
                        <span className="answer">Pembeli bebas untuk terus membuat pesanan walaupun cuaca tidak mengizinkan. Perkara ini tertakluk kepada rider-rider yang akan membuat penghantaran itu nanti.</span><br/>
                        <br/>    
                    </p>        
                </div>
            </div>  
        </div>
    );
}

function MobileView() {
    return (
        <div className="fourth-mobile">    
            <div className="row" style={{ paddingTop: "80px" }}>
                <div className="text-blue-theme text-raleway text-center" style={{ fontSize: "45px", marginBottom: "20px", width: "100%" }}>
                    Soalan Lazim (FAQ) berkenaan pembayaran
                </div>

                <div style={{ width: "100%" }}>
                    <p className="text-raleway small-message text-center" style={{ lineHeight: "1.6em" }}>
                    <span className="question">Apakah kaedah pembayaran yang tersedia di meniaga?</span><br/>
                        <span className="answer">Pembeli boleh membayar menggunakan kaedah Cash-On-Delivery (COD) ataupun dengan pembayaran secara online (FPX) menerusi payment gateway yang disediakan.</span><br/>
                        <br/>
                        <span className="question">Apa itu payment gateway (gerbang pembayaran)?</span><br/>
                        <span className="answer">Payment gateway ataupun gerbang pembayaran adalah pihak yang menghubungkan pembeli, peniaga dan bank di mana proses pembayaran boleh dibuat secara dalam talian.</span><br/>
                        <br/>
                        <span className="question">Sebagai seorang peniaga, apakah anggaran tempoh pemprosesan bayaran kepada saya daripada transaksi di aplikasi meniaga?</span><br/>
                        <span className="answer">Pihak kami memerlukan 4-10 hari bekerja bagi tujuan pemprosesan pembayaran kepada akaun bank peniaga masing-masing.</span><br/>             
                        <br/>
                        <span className="question">Apakah senarai bank yang boleh digunakan di aplikasi meniaga bagi tujuan pembayaran?</span><br />
                        <span className="answer">Semua jenis bank yang ada di Malaysia boleh digunakan semasa membuat pembayaran secara atas talian.</span><br/>
                        <br/>
                        <span className="question">Boleh ke saya buat pembayaran menggunakan Kad Kredit?</span><br />
                        <span className="answer">Buat masa sekarang , hanya kaedah secara FPX ditawarkan. Kami akan maklumkan dari masa ke masa sekiranya terdapat pengumuman terbaru.</span><br/>
                        <br/>
                        <span className="question">Adakah saya akan dicaj jika saya memilih untuk buat pembayaran menggunakan payment gateway?</span><br />
                        <span className="answer">Ya. Caj akan dikenakan kepada pembeli.</span>     
                    </p>        
                </div>

                <div className="text-blue-theme text-raleway text-center" style={{ fontSize: "45px", marginBottom: "20px", width: "100%" }}>
                    Soalan Lazim (FAQ) berkenaan penghantaran
                </div>

                <div style={{ width: "100%" }}>
                    <p className="text-raleway small-message text-center" style={{ lineHeight: "1.6em" }}>
                        <span className="question">Apakah kaedah penghantaran yang ada di aplikasi meniaga?</span><br/>
                        <span className="answer">Peniaga boleh memilih sama ada mahu buat penghantaran sendiri ataupun menggunakan perkhidmatan Bungkus-It dan Lalamove sebagai rider untuk menghantar produk anda kepada pembeli.</span><br/>
                        <br/>
                        <span className="question">Berapakah kadar pembayaran untuk menggunakan servis penghantaran Bungkus-It dan Lalamove?</span><br/>
                        <span className="answer">Kadar pembayaran untuk menggunakan servis penghantaran yang ada di meniaga tertakluk kepada kadar-kadar yang telah ditetapkan oleh Bungkus-It dan Lalamove sendiri.</span><br/>
                        <br/>
                        <span className="question">Jika saya memilih untuk buat penghantaran sendiri, berapa yang patut saya caj kepada pembeli?</span><br/>
                        <span className="answer">Penjual bebas untuk meletakkan sendiri harga caj kepada pembeli untuk khidmat penghantaran.</span><br/>             
                        <br/>
                        <span className="question">Apakah radius penghantaran di meniaga sekarang?</span><br />
                        <span className="answer">Buat masa ini, meniaga hanya beroperasi dalam radius 10km dari tempat pembeli ataupun penjual.</span><br/>
                        <br/>
                        <span className="question">Kalau cuaca hujan lebat, adakah pembeli masih boleh memilih untuk membuat pesanan untuk dihantar kepada pembeli?</span><br />
                        <span className="answer">Pembeli bebas untuk terus membuat pesanan walaupun cuaca tidak mengizinkan. Perkara ini tertakluk kepada rider-rider yang akan membuat penghantaran itu nanti.</span><br/>
                        <br/>
                    </p>        
                </div>
            </div>  
        </div>
    );
}

function FAQforPaymentAndDelivery() {
    return (
        <div>
            <div className="desktop-view">
                <DesktopView />
            </div>
            <div className="mobile-view">   
                <MobileView />
            </div>
        </div>
    );
}   

export default FAQforPaymentAndDelivery;