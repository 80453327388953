
import React from 'react';

import MeniagaDark from "../assets/meniaga-icon-darkmode.png";

import i18n from '../i18n.config';

function UserManual() {
  return (
    <div className="privacy-terms-conditions" style={{ backgroundColor: "#00334C" }}>
      <div className="desktop-view">
        <div className="row header" style={{ lineHeight: "20vh", justifyContent: "center" }}>
          <a href="/" style={{ justifyContent: "center" }}>
            <img src={MeniagaDark} className="meniaga-icon-manual" alt="meniagaIcon" style={{ height: 100 }}/>
          </a>
        </div>
      </div>

      <div className="mobile-view" style={{ margin: "auto", marginTop: 30 }}>
        <a href="/">
          <img src={MeniagaDark} className="meniaga-icon-manual" alt="meniagaIcon" />
        </a>
      </div>
      <br/>

      <div className="row content">
        <div className="col-md-12" style={{alignItems: 'center', width: '100%', justifyContent:'space-between'}}>
          <h4 className="font-weight-bold text-raleway text-white-theme text-center" style={{ marginTop: 40 }}>{i18n.t('howDoesItWork')}</h4><br/>   
          
          <div className="col" style={{justifyContent: "center" }}>
            <div className="row" style={{ marginTop: "40px", justifyContent: "center" }}>
                <div className="container-red font-weight-bold text-raleway text-white-theme text-center" style={{ height: "40%", width: "100%" }}>
                  {i18n.t('userGuide')}
                </div>
            </div> 
            <div class="iframeVideo">
              <iframe
                src={`https://www.youtube.com/embed/TpSfgavW7us?autoplay=1`}
                frameBorder="0"
                allowFullScreen
                title={i18n.t('userGuide')}
              />
            </div>
          </div>


          <div className="row" style={{justifyContent:'space-between'}}>
            <div className="col-12 col-md-6 referral-video" style={{alignItems: 'center'}}>
              <div className="row" style={{ marginTop: "80px", justifyContent: "center" }}>
                <div className="container-red font-weight-bold text-raleway text-white-theme text-center referral-video-title" style={{ height: "40%", width: "100%" }}>
                  {i18n.t('merchantCollectAndRedeemPoints')}
                </div>  
              </div> 
              <div class="iframeVideo">
                <iframe 
                  src={`https://www.youtube.com/embed/Pzu20lblPM0`}
                  frameBorder="0"
                  allowFullScreen
                  title={i18n.t('merchantCollectAndRedeemPoints')}
                />
              </div>
            </div>

            <div className="col-12 col-md-6 referral-video" style={{alignItems: 'center'}}>
              <div className="row" style={{ marginTop: "80px", justifyContent: "center" }}>
                <div className="container-red font-weight-bold text-raleway text-white-theme text-center referral-video-title" style={{ height: "40%", width: "100%" }}>
                  {i18n.t('buyerCollectAndRedeemPoints')}
                </div>
              </div> 
              <div class="iframeVideo" style={{ marginBottom: "80px" }}>
                <iframe
                  src={`https://www.youtube.com/embed/yo11-pf6FaM`}
                  frameBorder="0"
                  allowFullScreen
                  title={i18n.t('buyerCollectAndRedeemPoints')}
                />
              </div>
            </div>
          </div>
        </div>  
      </div>
    </div>
  );
}

export default UserManual;