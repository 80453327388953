
import React, { useEffect } from 'react';
import ReactLoading from 'react-loading';
import {
  isAndroid,
  isIOS,
  isMobile,
} from "react-device-detect";

function DeepLinkingRedirect(props) {
  const height = isMobile ? 40 : 100;
  const width = isMobile ? 40 : 100
  const merchant_id = props.match.params.id;
  const APP_STORE_URL = "https://apps.apple.com/my/app/meniaga-my/id1516839999";
  const PLAY_STORE_URL = "https://play.google.com/store/apps/details?id=com.invoke.meniaga.my";
  const WEBSITE_URL = "https://meniaga.my";
  const DEEP_LINK_URL = `meniaga:///merchant_store?merchant_id=${merchant_id}`

  useEffect(() => {
    let browser , hidden;
    let timeOut = 500;
    let userAgent =  navigator.userAgent;

    if (typeof document.hidden !== "undefined") {
      hidden = "hidden";
    } else if (typeof document.mozHidden !== "undefined") { // Firefox up to v17
      hidden = "mozHidden";
    } else if (typeof document.webkitHidden !== "undefined") { // Chrome up to v32, Android up to v4.4, Blackberry up to v10
      hidden = "webkitHidden";
    }

    if(userAgent.indexOf('FBAN') > -1 || userAgent.indexOf('FBAV') > -1){
      browser =  "Facebook"
      timeOut = 8000
    }else if(userAgent.indexOf('Instagram') > -1){
      browser = "Instagram"
      timeOut = 8000
    }else{
      browser = 'unknown'
    }

    // App scheme javascript redirection to the app is not working properly in Instagram in-app browser
    // Prompt user to open the link in Safari
    if(browser === 'Instagram' && isIOS){
      const modal = document.getElementById("modal")
      const loading = document.getElementById("loading")
      modal.setAttribute('style', 'display:block' )
      loading.setAttribute('style', 'display:none' )

      return
    }else{
      setTimeout(() => {
        if(isIOS && !document[hidden]){ 
          setTimeout(() => {
            return window.location.href = APP_STORE_URL
          },[5000])   
        } else if(isAndroid){
          return window.location.href = PLAY_STORE_URL
        }else {             
          // Redirect to landing page if using browser
          return window.location.href = WEBSITE_URL
        }
      // If redirect from Instagram or Facebook, add more timeout
      },timeOut)
      return window.location.href = DEEP_LINK_URL
    }
  },[merchant_id , DEEP_LINK_URL])

  return (
    <div className="redirect" >
      <div id="modal" className="alert alert-info" role="alert" style={{display:'none',width:"60vw"}}>
        <h6 className="instagram-instruction" >You will be redirected to<br/>
        <i style={{color:"#000"}}>meniaga.my/store/{merchant_id}.</i></h6>
        <h6 className="instagram-instruction" >To continue, please press <span className="ellipsis" >&#8943;</span> and choose<br/> "Open in Safari"</h6>
      </div>
      <div id="loading" >
        <h3 className="text-blue-theme"  >{`Mencari peniaga... `}</h3>
        <ReactLoading className="desktop-loading" type={'spin'} color={'#00334C'} height={height} width={width} />
      </div>
    </div>
  );
}

export default DeepLinkingRedirect;
