
import React from 'react';

import MeniagaLight from "../assets/meniaga-icon-lightmode.png";


function Privacy() {
  return (
    <div className="privacy-terms-conditions">
      <div className="desktop-view">
        <div className="row header" style={{ lineHeight: "10vh" }}>
          <a style={{ width: "180px", paddingLeft: "15px" }} href="/">
            <img src={MeniagaLight} className="meniaga-icon" alt="meniagaIcon" />
          </a>
          <div className="col-md-3">
            <p className="small text-raleway font-italic h-100 pt-1" style={{ color: '#00334C' }}>Pengantara Dagang Percuma</p>
          </div>
        </div>
      </div>
      
      <div className="mobile-view" style={{ margin: "auto" }}>
        <a href="/">
          <img src={MeniagaLight} className="meniaga-icon" alt="meniagaIcon" />
        </a>
        <p className="small text-raleway font-italic h-100 pt-1" style={{ color: '#00334C' }}>Pengantara Dagang Percuma</p>
      </div>

      <div className="row content">
        <h4 className="font-weight-bold text-raleway text-red-theme">PENYATA KERAHSIAAN MAKLUMAT PERIBADI</h4><br/>    

        <p className="text-raleway medium text-blue-theme" style={{ textAlign: "justify" }}>
            Penyata Kerahsiaan Maklumat Peribadi ini menjelaskan bagaimana <b>meniaga</b>, platform pengantara dagang yang dibangunkan dan dimiliki INVOKE Solutions Sdn Bhd (<b>“meniaga"</b>, “kami”) mengumpul, menyimpan, dan mengendali maklumat peribadi (seperti yang ditakrifkan di bawah) individu yang menggunakan <b>meniaga</b> di laman web atau aplikasi telefon pintar; selaras dengan Akta Perlindungan Data Peribadi 2010 (APDP) dan undang-undang Malaysia.<br/><br/>
            Sila baca Penyata Kerahsiaan Maklumat Peribadi ini untuk memahami bagaimana kami menggunakan Maklumat Peribadi yang kami mungkin kumpul daripada anda.<br/><br/>
            Dengan memberi Maklumat Peribadi anda kepada kami, anda bersetuju dengan syarat-syarat yang terkandung dalam Penyata Kerahsiaan Maklumat Peribadi ini mengenai pengumpulan, penggunaan, akses, pemindahan, penyimpanan dan pemprosesan maklumat peribadi anda seperti yang diterangkan dalam Penyata Kerahsiaan Maklumat Peribadi ini.
        </p>  

        <div className="mt-4">
          <h6 className="font-weight-bold text-raleway text-red-theme">Apa Yang Diterangkan Oleh Penyata Kerahsiaan Maklumat Peribadi Ini?</h6>
          <p className="text-raleway medium text-blue-theme" style={{ textAlign: "justify" }}>
              Penyata Kerahsiaan Maklumat Peribadi ini menerangkan:
          </p>  
          <ul className="text-raleway text-blue-theme">
            <li className="text-raleway">Apakah jenis maklumat peribadi yang kami kumpulkan</li>
            <li className="text-raleway">Bagaimana kami mengumpul maklumat peribadi anda</li>
            <li className="text-raleway">Bagaimana kami menggunakan maklumat peribadi anda</li>
            <li className="text-raleway">Bila kami mendedahkan maklumat peribadi anda</li>
            <li className="text-raleway">Hak anda untuk mengakses dan membetulkan maklumat peribadi anda</li>
          </ul>
        </div>


        <div className="mt-4">
          <h6 className="font-weight-bold text-raleway text-red-theme">Definisi Maklumat Peribadi</h6>
          <p className="text-raleway medium text-blue-theme" style={{ textAlign: "justify" }}>
              Maklumat peribadi merujuk kepada apa-apa maklumat yang berkaitan secara langsung atau tidak langsung kepada anda. 
              Ia merangkumi apa-apa maklumat yang boleh digunakan untuk membezakan, mengenal pasti atau menghubungi anda. 
          </p>  
        </div>

        <div className="mt-4">
          <h6 className="font-weight-bold text-raleway text-red-theme">Apakah Jenis Maklumat Peribadi Yang Kami Kumpulkan?</h6>
          <p className="text-raleway medium" style={{ textAlign: "justify" }}>
              Untuk membolehkan kami menyediakan Perkhidmatan dan beroperasi secara cekap dan berkesan dengan menyediakan perkhidmatan yang terbaik kepada anda, kami perlu mengumpul maklumat peribadi yang berkenaan daripada anda. 
              Maklumat peribadi yang dikumpulkan oleh kami mungkin merangkumi (tetapi tidak terhad kepada) yang berikut:-
          </p>  
          <ul className="text-raleway text-blue-theme">
            <li className="text-raleway">Maklumat hubungan seperti nama, tarikh lahir, dokumen-dokumen sokongan identiti (termasuk KP atau nombor pasport), jantina, kewarganegaraan dan bangsa, bahasa yang dipilih, alamat peribadi dan/atau perniagaan semasa, alamat pemasangan, nombor telefon atau telefon bimbit, nombor faks, alamat emel;</li>
            <li className="text-raleway">Maklumat butiran akaun bank;</li>
            <li className="text-raleway">Status Perkhidmatan yang anda telah perolehi atau langgani dari kami;</li>
            <li className="text-raleway">Bagaimana anda menggunakan Perkhidmatan;</li>
            <li className="text-raleway">Peralatan yang anda gunakan apabila mengakses Perkhidmatan kami (contohnya telefon bimbit, sistem komputer dan platform anda) untuk menyesuaikan perkhidmatan tersebut kepada anda;</li>
            <li className="text-raleway">Minat dan kegemaran peribadi anda untuk membantu kami melaraskan tawaran Perkhidmatan kami yang paling sesuai untuk anda;</li>
            <li className="text-raleway">Data pelayaran (“clickstream data”) apabila anda melawat mana-mana laman web kami (contohnya laman-laman yang anda akses dan Perkhidmatan yang anda layari);</li>
          </ul>
        </div>

        <div className="mt-4">
          <h6 className="font-weight-bold text-raleway text-red-theme">Bila dan Bagaimana Kami Mengumpul Maklumat Peribadi Anda?</h6>
          <p className="text-raleway medium text-blue-theme" style={{ textAlign: "justify" }}>
              Kami mungkin mengumpul maklumat peribadi secara terus daripada anda apabila anda:
          </p>  
          <ul className="text-raleway text-blue-theme">
            <li className="text-raleway">Berkomunikasi dengan kami</li>
            <li className="text-raleway">Mendaftar untuk atau melanggan Perkhidmatan tertentu</li>
            <li className="text-raleway">Menggunakan rangkaian kami dan Perkhidmatan lain</li>
            <li className="text-raleway">Mendaftar minat dan/atau memohon maklumat mengenai (melalui portal dalam talian atau saluran-saluran lain kami) atau melanggani Perkhidmatan kami</li>
            <li className="text-raleway">Menjawab apa-apa bahan pemasaran yang kami hantar keluar</li>
            <li className="text-raleway">Melawat atau melayari laman web kami</li>
            <li className="text-raleway">Membuat aduan kepada kami</li>
            <li className="text-raleway">Memberi maklum balas kepada kami (contohnya melalui laman web kami atau secara salinan bercetak)</li>
          </ul>
          <p className="text-raleway medium text-blue-theme" style={{ textAlign: "justify" }}>
              Selain daripada maklumat peribadi yang diperolehi secara terus daripada anda (seperti yang dinyatakan di atas), kami juga mungkin memperolehi maklumat peribadi anda daripada pihak-pihak ketiga yang berurusan dengan kami atau yang berkaitan dengan anda (Lembaga Zakat, jabatan kerajaan dan badan-badan lain), dan daripada mana-mana sumber-sumber lain di mana anda telah memberi kebenaran kepada pendedahan maklumat berkaitan dengan anda, dan/atau di mana dibenarkan oleh undang-undang.
          </p>
        </div>

        <div className="mt-4">
          <h6 className="font-weight-bold text-raleway text-red-theme">Bagaimana Kami Menggunakan Maklumat Peribadi Anda?</h6>
          <p className="text-raleway medium text-blue-theme" style={{ textAlign: "justify" }}>
              Kami mungkin mengumpul dan menggunakan maklumat peribadi daripada anda atau daripada kategori pihak-pihak ketiga yang dinyatakan dalam Penyata Kerahsiaan Maklumat Peribadi ini, untuk satu atau lebih tujuan-tujuan berikut:
          </p>
          <ul className="text-raleway text-blue-theme">
            <li className="text-raleway">Untuk mengesahkan identiti anda</li>
            <li className="text-raleway">Untuk mempertimbangkan dan memproses permohonan/permintaan anda untuk Perkhidmatan kami</li>
            <li className="text-raleway">Untuk menyediakan anda Perkhidmatan yang diminta </li>
            <li className="text-raleway">Untuk mengendali dan mengurus Perkhidmatan yang kami sediakan kepada anda (termasuk pengenaan bayaran, bil, kemudahan pembayaran dan memungut hutang)</li>
            <li className="text-raleway">Untuk menyiasat dan menyelesaikan apa-apa isu perkhidmatan, pertanyaan mengenai bil, aduan atau pertanyaan-pertanyaan lain yang anda beri kepada kami</li>
            <li className="text-raleway">Untuk mengesan dan mencegah aktiviti penipuan</li>
            <li className="text-raleway">Untuk menguruskan rangkaian kami</li>
            <li className="text-raleway">Untuk sentiasa berhubung dengan anda dan menyediakan anda dengan maklumat yang dipinta</li>
            <li className="text-raleway">Untuk memasuki urusan berkenaan Perkhidmatan yang hendak ditawarkan dan disediakan kepada anda</li>
            <li className="text-raleway">Untuk menjalin dan menguruskan dengan lebih baik apa-apa urusan yang kami mungkin ada dengan anda</li>
            <li className="text-raleway">Untuk memproses komunikasi yang anda hantar kepada kami (contohnya, menjawab pertanyaan dan menangani apa-apa aduan dan maklum balas)</li>
            <li className="text-raleway">Untuk menyenggara dan membina sistem dan infrastruktur kami, termasuk pengujian dan penaikan taraf sistem-sistem tersebut</li>
            <li className="text-raleway">Untuk menguruskan latihan kakitangan dan jaminan kualiti</li>
            <li className="text-raleway">Untuk memaklumkan anda mengenai manfaat dan perubahan kepada ciri-ciri Perkhidmatan kami</li>
            <li className="text-raleway">Untuk memastikan cara kami dapat memperbaiki perkhidmatan kami kepada anda</li>
            <li className="text-raleway">Untuk menyenggara rekod-rekod yang diperlukan untuk tujuan keselamatan, tuntutan atau tujuan undang-undang yang lain</li>
            <li className="text-raleway">Untuk mematuhi keperluan undang-undang dan kawal selia</li>
            <li className="text-raleway">Untuk apa-apa tujuan lain yang diperlukan atau dibenarkan oleh apa-apa undang-undang, peraturan, garis panduan dan/atau pihak-pihak kawal selia berkuasa yang berkenaan</li>
          </ul> 
        </div> 

        <div className="mt-4">
          <h6 className="font-weight-bold text-raleway text-red-theme">Pendedahan Maklumat Peribadi Anda</h6>
          <p className="text-raleway medium text-blue-theme" style={{ textAlign: "justify" }}>
              Sebagai sebahagian dari penyediaan Perkhidmatan kami dan pengurusan dan/atau operasinya, kami mungkin diperlukan atau dikehendaki mendedahkan maklumat mengenai anda kepada pihak-pihak ketiga yang berikut:
          </p>  
          <ul className="text-raleway text-blue-theme">
            <li className="text-raleway">Agensi-agensi penguatkuasa undang-undang</li>
            <li className="text-raleway">Agensi-agensi kerajaan yang lain</li>
            <li className="text-raleway">Syarikat-syarikat dan/atau organisasi yang bertindak sebagai ejen, kontraktor, pembekal perkhidmatan dan/atau penasihat profesional kami</li>
            <li className="text-raleway">Syarikat-syarikat dan/atau organisasi yang membantu kami memproses dan/atau sebaliknya memenuhi urusan dan menyediakan anda Perkhidmatan yang anda telah pohon atau langgani</li>
            <li className="text-raleway">Pihak-pihak lain di mana anda telah memberikan kebenaran nyata atau tersirat</li>
          </ul>
        </div>

        <div className="mt-4">
          <h6 className="font-weight-bold text-raleway text-red-theme">Bagaimana Jika Maklumat Peribadi Yang Diberi Anda Tidak Lengkap?</h6>
          <p className="text-raleway medium text-blue-theme" style={{ textAlign: "justify" }}>
              Di mana dinyatakan (contohnya dalam borang pendaftaran/permohonan), anda diwajibkan memberi maklumat peribadi kepada kami untuk membolehkan kami memproses permohonan anda untuk Perkhidmatan kami, jika anda enggan memberi maklumat peribadi yang diwajibkan tersebut, kami mungkin tidak dapat memproses permohonan anda atau menyediakan anda Perkhidmatan kami.
          </p>  
        </div>

        <div className="mt-4">
          <h6 className="font-weight-bold text-raleway text-red-theme">Hak Anda Untuk Mengakses Dan Membetulkan Maklumat Peribadi Anda</h6>
          <p className="text-raleway medium text-blue-theme" style={{ textAlign: "justify" }}>
              Kami boleh membantu anda untuk mengakses dan membetulkan maklumat peribadi anda yang dipegang oleh kami. <br/><br/>
              Jika anda mahu mengakses maklumat peribadi anda yang dipegang oleh kami, atau jika anda berpendapat bahawa maklumat peribadi anda yang dipegang oleh kami tidak tepat, tidak lengkap, mengelirukan atau jika berkenaan, tidak terkini, anda boleh membuat permintaan kepada kami dengan menghubungi <b>meniaga</b> melalui butiran cara menghubungi kami yang dinyatakan di laman atau rangkaian kami. Anda harus menyatakan nama, alamat dan nombor telefon/akaun anda dan nyatakan dengan ringkas maklumat berkenaan yang mana anda ingin minta salinan.<br/><br/>
              Kami akan berusaha secara berpatutan untuk memenuhi permintaan anda untuk mengakses atau membetulkan maklumat peribadi anda dalam masa 21 hari dari tarikh kami menerima permintaan anda.<br/><br/>
              Sila maklum bahawa kami mungkin perlu menahan akses kepada maklumat peribadi anda dalam keadaan tertentu, contohnya apabila kami tidak dapat memastikan identiti anda atau di mana maklumat yang diminta bersifat komersial dan sulit, atau jika kami menerima permintaan berulang untuk maklumat yang sama. Namun demikian, kami akan memaklumkan anda akan sebab-sebab kenapa kami tidak dapat memenuhi permintaan anda.
          </p>  
        </div>
        
        <div className="mt-4">
          <h6 className="font-weight-bold text-raleway text-red-theme">Kewajipan Anda</h6>
          <p className="text-raleway medium text-blue-theme" style={{ textAlign: "justify" }}>
              Anda bertanggungjawab memberikan maklumat yang tepat dan lengkap kepada kami mengenai diri anda dan mengenai mana-mana individu di mana anda membekalkan maklumat peribadi mereka kepada kami dan untuk mengemaskinikan maklumat peribadi tersebut jika dan apabila ia menjadi tidak betul atau tidak terkini dengan menghubungi kami. Kewajipan ini merupakan syarat bagi penyediaan Perkhidmatan kami kepada anda dan/atau individu-individu lain yang disahkan atau dibenarkan oleh anda atau organisasi/syarikat anda untuk menggunakan Perkhidmatan tersebut.<br/><br/>
              Jika anda perlu memberi kami maklumat peribadi berkaitan dengan pihak-pihak ketiga (contohnya mengenai suami atau isteri atau anak-anak anda, atau di mana anda merupakan orang yang dilantik dalam syarikat atau organisasi untuk berurusan dengan kami, jika anda sedang memperolehi dan bertanggungjawab untuk perkhidmatan dan/atau produk yang mereka akan gunakan), anda mengesahkan bahawa anda telah <b>(i)</b> mendapatkan kebenaran mereka atau sebaliknya anda berhak memberi maklumat peribadi mereka kepada kami dan untuk kami menggunakan maklumat peribadi tersebut dengan sewajarnya, dan <b>(ii)</b> memberitahu mereka untuk membaca Penyata Kerahsiaan Maklumat Peribadi ini di laman ini.
          </p>  
        </div>
        
        <div className="mt-4 mb-5">
          <h6 className="font-weight-bold text-raleway text-red-theme">Urusan Dalam Talian Dengan Kami</h6>
          <p className="text-raleway medium text-blue-theme" style={{ textAlign: "justify" }}>
              <i><b>Cookies</b></i><br/>
              Kami mengumpul maklumat mengenai penggunaan laman web kami oleh anda dari <i>“cookies”</i>. <i>“Cookies”</i> adalah paket-paket maklumat yang disimpan dalam komputer anda yang membantu pelayaran laman web anda dengan menyesuaikan maklumat laman web kepada keperluan anda. <i>Cookies</i> dengan sendirinya tidak mengenal pasti pengguna individu, hanya komputer yang digunakan. Anda tidak diwajibkan menerima <i>cookies</i>. Jika anda mempunyai kerisauan, anda boleh tetapkan komputer anda untuk sama ada menerima semua <i>cookies</i>, memaklumkan anda bila sesuatu <i>cookie</i> di isu, atau langsung tidak menerima <i>cookies</i> pada apa jua masa. Namun demikian, penolakan <i>cookies</i> mungkin akan menjejaskan penggunaan laman web kami kerana kami tidak akan dapat memperibadikan aspek-aspek penggunaan laman web oleh anda.<br/><br/>

              <i><b>Pautan Ke Laman Web Pihak-Pihak Ketiga</b></i><br/>
              Sila maklum bahawa Penyata Kerahsiaan Maklumat Peribadi ini hanya terpakai di laman <b>meniaga</b> dan tidak terpakai kepada apa-apa laman web pihak-pihak ketiga yang anda mungkin akses dari laman web kami. Untuk menentukan bagaimana mereka mengendali maklumat peribadi anda, anda harus memastikan bahawa anda membaca polisi privasi mereka masing-masing.<br/><br/>

              <i><b>Pemindahan Maklumat Peribadi Anda Ke Luar Negara</b></i><br/>
              Kami tidak akan memindahkan maklumat peribadi anda ke luar negara.<br/><br/>
              
              <i><b>Pengemaskinian Kepada Penyata Kerahsiaan Maklumat Peribadi Kami</b></i><br/>
              Kami mungkin akan meminda Penyata Kerahsiaan Maklumat Peribadi ini dari masa ke semasa dan versi terkini akan digunapakai dan menggantikan sebarang dan semua versi terdahulu, termasuk tetapi tidak terhad kepada versi di dalam risalah atau salinan bercetak. Sila semak laman web kami untuk maklumat mengenai amalan-amalan paling terkini kami.
          </p>  
        </div>

      </div>
    </div>
  );
}

export default Privacy;