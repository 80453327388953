import React from 'react';

import MeniagaLight from "../assets/meniaga-icon-lightmode.png";

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import "../App.css";
import i18n from '../i18n.config';


function DesktopView() {
    return (
        <div>
            <div className="row bg-blue-theme footer" style={{ height: "150px" }}>
                <div style={{ height: "150px", display: "flex", justifyContent: "flex-start", width: "45%" }}>
                    {i18n.language == 'my' ?
                        <p className="text-white medium-message" style={{ margin: "auto" }}>
                            Muat turun aplikasi <span className="font-weight-bold font-italic">meniaga</span>
                        </p>
                        : <p className="text-white medium-message" style={{ margin: "auto" }}>
                            Download <span className="font-weight-bold font-italic">meniaga</span> app now
                        </p>
                    }
                    <div className="text-white circle-icon text-center" style={{ margin: "auto 10px", lineHeight: "30px", paddingRight: "3px" }}>
                        <div style={{ transform: "rotate(270deg)" }}>{<ArrowDownwardIcon />}</div>
                    </div>
                </div>
                <div className="row" style={{ height: "150px", display: "flex", justifyContent: "flex-end", width: "50%" }}>
                    <div style={{ margin: "auto 10px" }}>
                        <div className="button-download-large apple">
                            <a className="button-link" target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/my/app/meniaga-my/id1516839999">
                            </a>
                        </div>
                    </div>
                    <div style={{ margin: "auto 10px" }}>
                        <div className="button-download-large google">
                            <a className="button-link" target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.invoke.meniaga.my">
                            </a>
                        </div>
                    </div>
                </div>

            </div>
            <div className="row bg-red-theme footer" style={{ height: "10px" }} />

            <div className="row bg-white footer" style={{ height: "250px", position: "relative" }}>
                <div className="" style={{ width: "33.3%", paddingLeft: "15px", position: "relative" }}>
                    <div style={{ position: "absolute", bottom: 0 }}>
                        <img src={MeniagaLight} className="meniaga-icon" alt="meniagaIcon" />
                        <p className="small text-raleway text-blue-theme">
                            <b>{i18n.t('followUs')}</b> <a className="footer-link text-blue-theme" target="_blank" rel="noopener noreferrer" href="https://facebook.com/meniaga.my">Facebook</a> | <a className="footer-link text-blue-theme" target="_blank" rel="noopener noreferrer" href="https://twitter.com/MyMeniaga?s=20">Twitter</a> | <a className="footer-link text-blue-theme" target="_blank" rel="noopener noreferrer" href="https://instagram.com/meniagamy">Instagram</a> <br />
                            {i18n.t('contactInfo')}
                        </p>
                    </div>
                </div>
                <div className="" style={{ width: "33.3%", paddingLeft: "15px", position: "relative" }}>
                    <p className="small text-raleway text-blue-theme" style={{ position: "absolute", bottom: 0, left: 100 }}>
                        &copy; {new Date().getFullYear()} {i18n.t('company')}
                    </p>
                </div>
                <div className="" style={{ width: "33.3%", paddingLeft: "15px", position: "relative" }}>
                    <p className="small text-raleway" style={{ position: "absolute", bottom: 0, right: 5 }}>
                        <a className="footer-link text-blue-theme" href="/privacy">{i18n.t('confidentialityStatement')}</a> | <br /><a className="footer-link text-blue-theme" href="/terms_conditions">{i18n.t('termsAndCondition')}</a> | <br /><a className="footer-link text-blue-theme" href="/payment_gateway_terms_conditions">{i18n.t('paymentAndDeliveryTNC')}</a> | <br /><a className="footer-link text-blue-theme" href="/faq_payment_delivery">{i18n.t('paymentAndShippingFAQ')} </a>
                    </p>
                </div>
            </div>
        </div>
    );
}

function MobileView() {
    return (
        <div>
            <div className="row bg-blue-theme footer-mobile" style={{ height: "150px" }}>
                <div style={{ height: "150px", display: "flex", justifyContent: "flex-start", width: "45%" }}>
                    {i18n.language == 'my' ?
                        <p className="text-white text-raleway" style={{ margin: "auto" }}>
                            Muat turun aplikasi <span className="font-weight-bold font-italic">meniaga</span>
                        </p>
                        : <p className="text-white text-raleway" style={{ margin: "auto" }}>Download <span className="font-weight-bold font-italic">meniaga</span> app now
                        </p>
                    }
                    <div className="text-white circle-icon text-center" style={{ margin: "auto 10px", lineHeight: "30px", paddingRight: "3px" }}>
                        <div style={{ transform: "rotate(270deg)" }}>{<ArrowDownwardIcon />}</div>
                    </div>
                </div>
                <div style={{ width: "5%" }} />
                <div style={{ width: "50%" }}>
                    <div className=" mt-3 button-download-mobile apple">
                        <a className="button-link" target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/my/app/meniaga-my/id1516839999">
                        </a>
                    </div>
                    <div className="mt-3 button-download-mobile google">
                        <a className="button-link" target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.invoke.meniaga.my">
                        </a>
                    </div>
                </div>

            </div>
            <div className="row bg-red-theme footer-mobile" style={{ height: "10px" }} />

            <div className="row bg-white footer-mobile" style={{ height: "250px", paddingTop: "50px" }}>
                <div style={{ width: "100%" }}>
                    <div className="text-center mb-3">
                        <img src={MeniagaLight} className="meniaga-icon" alt="meniagaIcon" />
                    </div>
                    <p className="small text-raleway text-blue-theme text-center">
                        <b>Follow us</b> <a className="footer-link text-blue-theme" target="_blank" rel="noopener noreferrer" href="https://facebook.com/meniaga.my">Facebook</a> | <a className="footer-link text-blue-theme" target="_blank" rel="noopener noreferrer" href="https://twitter.com/MyMeniaga?s=20">Twitter</a> | <a className="footer-link text-blue-theme" target="_blank" rel="noopener noreferrer" href="https://instagram.com/meniagamy">Instagram</a> <br />
                        {i18n.t('contactInfo')}
                    </p>
                    <p className="small text-raleway text-blue-theme text-center">
                        {i18n.t('company')}
                    </p>
                    <p className="small text-raleway text-center">
                        <a className="footer-link text-blue-theme" href="/privacy">{i18n.t('confidentialityStatement')}</a> | <br /><a className="footer-link text-blue-theme" href="/terms_conditions">{i18n.t('termsAndCondition')}</a> | <br /><a className="footer-link text-blue-theme" href="/payment_gateway_terms_conditions">{i18n.t('paymentAndDeliveryTNC')}</a> | <br /><a className="footer-link text-blue-theme" href="/faq_payment_delivery">{i18n.t('paymentAndShippingFAQ')} </a>
                    </p>
                </div>
            </div>
        </div>
    );
}


function Footer(props) {
    if (props.visible) {
        return (
            <div>
                <div className="desktop-view">
                    <DesktopView />
                </div>
                <div className="mobile-view">
                    <MobileView />
                </div>
            </div>
        );
    }
    else {
        return null;
    }

}

export default Footer;
