import React from 'react';

import MeniagaDark from "../assets/meniaga-icon-darkmode.png";
import MeniagaLight from "../assets/meniaga-icon-lightmode.png";
import MobileApp1 from "../assets/Meniaga_APPS-SS01@2x.png";
import Raya from "../assets/icon/bulan-bintang.png";

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import "../App.css";
import i18n from '../i18n.config';

import { MenuItem, Select, makeStyles } from '@material-ui/core';

const DesktopView = ({ parentSelected, parentSetSelected, parentHandleChange }) => {

    return (
        <div className="first">
            <div className="row content">
                <div style={{ paddingTop: "120px", position: "relative", width: "40%" }}>
                    <div className="text-white large-message">{i18n.t('homeBased')}</div>
                    <p className="text-white small-message mt-5 mr-5">
                        {i18n.t('sellOnMeniagaDesc')}<br />
                        {i18n.t('distanceDesc')}.<br /> <br />
                        {i18n.t('everyoneCanStartABusiness')}<br />
                    </p>
                    <div className="text-white circle-icon text-center" style={{ position: "absolute", bottom: 10, left: 10 }}>
                        {<ArrowDownwardIcon />}
                    </div>
                </div>

                <div style={{ width: "30%" }}>
                    <img src={MobileApp1} className="large-image" alt="mobile1" />
                </div>

                <div style={{ paddingTop: "320px", width: "30%" }}>

                    {i18n.language == 'my' ?
                        <p className="text-white medium-message">
                            Muat turun<br />aplikasi <span className="font-italic meniaga-bold">meniaga</span><br />sekarang
                        </p>
                        : <p className="text-white medium-message">
                            Download<br /><span className="font-italic meniaga-bold">meniaga</span> app<br />now
                        </p>

                    }
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "130px" }}>
                            <div className="button-download apple">
                                <a className="button-link" target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/my/app/meniaga-my/id1516839999">
                                </a>
                            </div>
                        </div>
                        <div style={{ width: "130px" }}>
                            <div className="button-download google">
                                <a className="button-link" target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.invoke.meniaga.my">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

/* MOBILE VIEW */
function MobileView({ parentSelected, parentSetSelected, parentHandleChange }) {

    const useStyles = makeStyles({
        select: {
            "& .MuiSvgIcon-root": {
                color: "white",
            },
            width: 70,
            height: 40,
            backgroundColor: '#00334C',
            paddingLeft: 15,
            border: 'solid',
            borderWidth: 1,
            borderRadius: 8,
            borderColor: '#808080',
            color: '#FFFFFF',
            fontFamily: 'Raleway',
            fontSize: 15,
            fontWeight: 'bold',
        },
    });

    const classes = useStyles();

    return (
        <div className="row first-mobile">
            <div style={{ display: "none"}}>
                <div style={{ margin: "auto" }}>
                    <img src={MeniagaDark} className="meniaga-icon" alt="meniagaIcon" />
                    <p className="small text-raleway text-white font-italic">{i18n.t('freeECommercePlatform')}</p>
                </div>
                <div>
                    {/* <select className='small text-raleway text-white p-2 font-weight-bold' style={{ backgroundColor: '#00334C', borderRadius: 5 }} value={parentSelected} onChange={parentHandleChange}>
                        <option value="my">Bahasa Melayu</option>
                        <option value="en">English</option>
                    </select> */}

                    <Select
                        disableUnderline
                        value={parentSelected}
                        onChange={parentHandleChange}
                        displayEmpty
                        className={classes.select}
                    >
                        <MenuItem value='my'>BM</MenuItem>
                        <MenuItem value='en'>EN</MenuItem>
                    </Select>

                </div>
            </div>

            <div style={{ width: "100%" }}>
                <div className="text-white text-center large-message mt-5" style={{ fontSize: "65px" }}>{i18n.t('homeBased')}</div>
                <p className="text-white small-message mt-5 text-center">
                    {i18n.t('sellOnMeniagaDesc')}<br />
                    {i18n.t('distanceDesc')}.<br />
                    {i18n.t('everyoneCanStartABusiness')}<br />
                </p>

                <div className="mt-1" style={{ display: "flex", justifyContent: "center" }}>
                    <div className="text-white circle-icon text-center">
                        {<ArrowDownwardIcon />}
                    </div>
                </div>

                <img src={MobileApp1} className="large-image mt-5" alt="mobile1" style={{ margin: "auto" }} />

                {i18n.language == 'my' ?
                    <p className="text-white medium-message text-center mt-5">
                        Muat turun<br />aplikasi <span className="font-weight-bold font-italic">meniaga</span><br />sekarang
                    </p>
                    : <p className="text-white medium-message text-center mt-5">
                        Download<br /><span className="font-weight-bold font-italic">meniaga</span> app<br />now
                    </p>
                }

                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                    <div style={{ width: "130px" }}>
                        <div className="button-download apple">
                            <a className="button-link" target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/my/app/meniaga-my/id1516839999">
                            </a>
                        </div>
                    </div>
                    <div style={{ width: "130px", display: "flex", justifyContent: "flex-end" }}>
                        <div className="button-download google">
                            <a className="button-link" target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.invoke.meniaga.my">
                            </a>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
}


function FirstPage({ parentSelected, parentSetSelected, parentHandleChange }) {
    return (
        <div>
            <div className="desktop-view">
                <DesktopView
                    parentSelected={parentSelected}
                    parentSetSelected={parentSetSelected}
                    parentHandleChange={parentHandleChange}
                />
            </div>
            <div className="mobile-view">
                <MobileView
                    parentSelected={parentSelected}
                    parentSetSelected={parentSetSelected}
                    parentHandleChange={parentHandleChange}
                />
            </div>
        </div>
    );
}

export default FirstPage;
