import React from 'react';

import MeniagaLight from "../assets/meniaga-icon-lightmode.png";

function TermsAndConditions() {
  return (
    <div className="privacy-terms-conditions">
      <div className="desktop-view">
        <div className="row header" style={{ lineHeight: "10vh" }}>
          <a style={{ width: "180px", paddingLeft: "15px" }} href="/">
            <img src={MeniagaLight} className="meniaga-icon" alt="meniagaIcon" />
          </a>
          <div className="col-md-3">
            <p className="small text-raleway font-italic h-100 pt-1" style={{ color: '#00334C' }}>Pengantara Dagang Percuma</p>
          </div>
        </div>
      </div>
      
      <div className="mobile-view" style={{ margin: "auto" }}>
        <a href="/">
          <img src={MeniagaLight} className="meniaga-icon" alt="meniagaIcon" />
        </a>
        <p className="small text-raleway font-italic h-100 pt-1" style={{ color: '#00334C' }}>Pengantara Dagang Percuma</p>
      </div>

      <div className="row content">
        <h4 className="font-weight-bold text-raleway text-red-theme">TERMA DAN SYARAT PENGGUNAAN</h4><br/>    

        <div className="mt-4">
          <h6 className="font-weight-bold text-raleway text-red-theme">Pengenalan</h6>
          <p className="text-raleway medium text-blue-theme" style={{ textAlign: "justify" }}>
            Anda diminta membaca halaman ini dengan teliti. Ia mengandungi terma dan syarat (selanjutnya disebut "Terma dan Syarat") yang mengatur akses dan penggunaan perkhidmatan dan laman web <b>meniaga</b> oleh anda, sama ada secara terus atau sebahagiannya melalui <b>meniaga</b> (secara bersama dirujuk sebagai "Laman") yang anda layari sama ada di laman web dan/atau versi mudah alih di telefon pintar, termasuk sebarang aplikasi telefon pintar <b>meniaga</b> (secara bersama dirujuk sebagai <b>"aplikasi meniaga"</b>), yang disediakan oleh INVOKE Solutions Sdn Bhd dan/atau salah satu syarikat bersekutunya.<br/><br/>
            
            Sekiranya anda tidak menerima Terma dan Syarat ini atau anda tidak memenuhi atau mematuhi peruntukannya, anda tidak boleh menggunakan Laman web ini. Terma dan Syarat ini dikuatkuasa pada tarikh pelancaran laman web dan aplikasi telefon pintar <b>meniaga</b>.
          </p>  
        </div>

        <div className="mt-4">
          <h6 className="font-weight-bold text-raleway text-red-theme">Perjanjian</h6>
          <p className="text-raleway medium text-blue-theme" style={{ textAlign: "justify" }}>
            Setiap kali anda mengakses atau menggunakan aplikasi <b>meniaga</b>, tidak kira dari mana ia dimuat turun, dan mana-mana perisian yang disediakan oleh atau atas nama <b>meniaga</b> atau yang berkaitan dengan Laman tersebut:            
          </p>     
          <ul className="text-raleway text-blue-theme">
            <li className="text-raleway">anda menyatakan bahawa anda telah membaca dan memahami semua dasar pada aplikasi dan Laman <b>meniaga</b>; dan</li>
            <li className="text-raleway">anda dengan ini bersetuju dengan Terma dan Syarat yang dinyatakan di sini dan di dalamnya.</li>
          </ul>

          <p className="text-raleway medium text-blue-theme" style={{ textAlign: "justify" }}>
            Semua rujukan untuk "kami", <b>"meniaga"</b>, "INVOKE Solutions Sdn Bhd" merujuk kepada entiti yang sama yang dinyatakan di atas.<br/><br/>

            Terma dan Syarat (seperti yang tertera di sini dan mungkin dipinda dari semasa ke semasa) membentuk perjanjian yang mengikat ("Perjanjian") antara anda dan <b>meniaga</b>.<br/><br/>

            Akses anda atau penggunaan perkhidmatan <b>meniaga</b> menunjukkan penerimaan anda terhadap Terma dan Syarat ini. Anda bersetuju untuk menggunakan Laman web atas risiko anda sendiri.       
          </p>  
        </div>

        <div className="mt-4">
          <h6 className="font-weight-bold text-raleway text-red-theme">Definisi</h6>
          <table>
            <tbody>
              <tr className="text-raleway text-blue-theme">
                <td className="row-term">Bahan <b>meniaga</b></td>
                <td className="row-definition">Sebarang bahan, metodologi, rancangan pelaksanaan atau harta intelek lain yang digunakan semasa penyediaan perkhidmatan <b>meniaga</b>.</td>
              </tr>
              <tr className="text-raleway text-blue-theme">
                <td className="row-term">Tapak</td>
                <td className="row-definition">Mana-mana laman web di bawah kawalan <b>meniaga</b>, sama ada sebahagian atau sebaliknya dan merangkumi Kandungan, Bahan dan Perkhidmatan <b>meniaga</b> (mengikut konteks penggunaan).</td>
              </tr>    
              <tr className="text-raleway text-blue-theme">
                <td className="row-term">Kandungan</td>
                <td className="row-definition">Halaman web <b>meniaga</b>, borang web, pengaturcaraan (<i>termasuk kod perisian yang digunakan di Laman dan dalam Perkhidmatan, termasuk <b>(i)</b> alat, kit, dan perpustakaan objek, <b>(ii)</b> semua kod pihak ketiga atau sumber terbuka yang disertakan di dalamnya, dan <b>(iii)</b> sebarang tambah baik, kemas kini, versi, pembetulan, peningkatan atau pengubahsuaian kepada perkara di atas</i>), grafik, gambar, reka bentuk (kombinasi warna dan susun atur halaman), teks, maklumat, data, katalog yang disimpan dalam pelbagai pangkalan data komersial yang dikendalikan dan dilesenkan oleh <b>meniaga</b>, data yang dihantar melalui Laman oleh Pengguna dan kandungan lain yang disediakan melalui Laman oleh <b>meniaga</b>.</td>
              </tr>  
              <tr className="text-raleway text-blue-theme">
                <td className="row-term">Dokumen</td>
                <td className="row-definition">Sebarang pengeposan ke Laman, sama ada katalog produk atau sebarang maklumat yang dihantar oleh sebarang Pengguna.</td>
              </tr>  
              <tr className="text-raleway text-blue-theme">
                <td className="row-term">Peniaga</td>
                <td className="row-definition">Seseorang atau entiti yang mengakses Laman untuk mengiklankan barangan dan perkhidmatan atau menggunakan Perkhidmatan dengan alasan apa pun yang berkaitan dengan tujuan menjual barang dan perkhidmatan.</td>
              </tr> 
              <tr className="text-raleway text-blue-theme">
                <td className="row-term">Bahan Peniaga</td>
                <td className="row-definition">Sebarang brosur, e-mel, maklumat katalog dan produk, kandungan laman web, bahan pameran katalog dan produk, audio, video, gambar, logo, tanda dagang, tanda perkhidmatan, nama domain, dokumen atau bahan lain yang disediakan oleh Peniaga, jika ada, untuk digunakan berkaitan dengan Perkhidmatan.</td>
              </tr> 
              <tr className="text-raleway text-blue-theme">
                <td className="row-term">Pembeli</td>
                <td className="row-definition">Pengguna yang mengakses Laman web bagi tujuan membeli, sama ada melayari katalog produk, membuat pesanan untuk membeli atau mengakses Laman dalam kapasiti lain kecuali sebagai Peniaga.</td>
              </tr> 
              <tr className="text-raleway text-blue-theme">
                <td className="row-term">Perkhidmatan</td>
                <td className="row-definition">Sebarang perkhidmatan yang disediakan oleh <b>meniaga</b> atau ejennya yang dinyatakan di sini dan dinyatakan dengan lebih lengkap di tempat yang berkaitan.</td>
              </tr> 
              <tr className="text-raleway text-blue-theme">
                <td className="row-term">Pengguna</td>
                <td className="row-definition">Mana-mana individu atau entiti yang menggunakan sebarang ciri-ciri dari Laman web.</td>
              </tr> 
              <tr className="text-raleway text-blue-theme">
                <td className="row-term">Anda atau anda</td>
                <td className="row-definition">Orang (atau entiti bagi pihak yang anda bertindak) yang bersetuju dengan Terma dan Syarat ini.</td>
              </tr> 
            </tbody>
          </table>
        </div>

        <div className="mt-4">
          <h6 className="font-weight-bold text-raleway text-red-theme">Hak Harta Intelek</h6>
          <p className="text-raleway medium text-blue-theme" style={{ textAlign: "justify" }}>
            Laman web, Bahan <b>meniaga</b> dan semua hak, tajuk dan kepentingan dalam dan ke Laman dan Bahan <b>meniaga</b> adalah hak milik
            sepenuhnya <b>meniaga</b> atau pemberi lesennya, dan dilindungi oleh hak cipta, tanda dagang dan undang-undang lain dari Malaysia dan asing. Kecuali
            untuk lesen terhad yang diberikan secara jelas kepada anda dalam Terma dan Syarat ini, semua hak dan kepentingan lain adalah milik <b>meniaga</b> dan
            pemberi lesennya. Anda tidak boleh memperbanyak, mengubah, memaparkan, menjual, atau menyebarkan Kandungan atau Bahan <b>meniaga</b>,
            atau menggunakannya dengan cara lain untuk tujuan awam atau komersial. Tanpa tertakluk kepada apa pun yang bertentangan yang
            dinyatakan di sini, larangan ini termasuk: <b>(a)</b> menyalin atau menyesuaikan kod HTML yang digunakan untuk menghasilkan laman web di Laman; <b>(b)</b> menggunakan atau cuba menggunakan enjin, perisian, manual, atau
            perisian automatik, alat, peranti, ejen, skrip, robot atau cara lain, peranti, mekanisme atau proses (termasuk, tetapi tidak terhad kepada, penyemak
            imbas, labah-labah, robot atau avatar) untuk melayari, mencari, mengakses, "mengikis", "merangkak", atau "labah-labah" mana-mana laman web atau
            Perkhidmatan yang disediakan di Laman selain mesin pencari dan ejen carian yang tersedia dari <b>meniaga</b> di Laman <b>meniaga</b> tersebut dan selain
            daripada amnya penyemak imbas web pihak ketiga yang tersedia (contohnya, Internet Explorer, Firefox, Safari); dan <b>(c)</b> menggabungkan,
            menyalin atau menduplikasi dengan apa-apa cara mana-mana Kandungan atau maklumat yang tersedia dari mana-mana Laman <b>meniaga</b>, tanpa
            persetujuan bertulis daripada <b>meniaga</b>. Penggunaan Kandungan di mana-mana laman web lain atau dalam persekitaran komputer berangkaian untuk
            tujuan apa pun dilarang sama sekali. Bahan yang tidak dianggap sebagai karya upahan boleh dicetak seperti yang dibenarkan dari <b>meniaga</b> seperti
            dinyatakan di dalam Terma dan Syarat. Reka bentuk logo dan beberapa nama atau logo lain adalah tanda perkhidmatan atau tanda dagangan <b>meniaga</b>,
            dan semua nama produk dan perkhidmatan yang berkaitan, tanda reka bentuk dan slogan adalah tanda perkhidmatan atau tanda
            dagangan <b>meniaga</b>. Selain itu, "rupa" dan "nuansa" Laman (termasuk kombinasi warna, bentuk butang, susun atur, reka bentuk dan
            semua elemen grafik lain) juga dilindungi oleh tanda dagangan, tanda servis, dan hak cipta <b>meniaga</b>. Segala kod yang dibuat oleh <b>meniaga</b> untuk
            menghasilkan atau memaparkan Kandungan atau halaman yang membentuk Laman ini juga dilindungi oleh hak cipta <b>meniaga</b>. Anda mesti
            menyimpan semua hak cipta, cap dagang, tanda perkhidmatan dan pemberitahuan hak milik lain yang terdapat pada Kandungan
            atau Bahan <b>meniaga</b> pada setiap salinan sah yang anda buat dari Kandungan atau Bahan <b>meniaga</b>. Semua tanda produk dan perkhidmatan lain yang
            terdapat di Laman web adalah tanda dagang pemiliknya masing-masing.
          </p>  
        </div>

        <div className="mt-4">
          <h6 className="font-weight-bold text-raleway text-red-theme">Syarat Penggunaan</h6>
          <p className="text-raleway medium text-blue-theme" style={{ textAlign: "justify" }}>
            <u>Penggunaan am</u><br/>
            Laman web ini ditujukan untuk individu yang ingin menjual barang
            (makanan, makanan basah, ternakan, sayuran dan produk kecil yang serupa
            dengannya) dan perkhidmatan (kecil-kecilan seperti mengambil upah,
            menjahit, membaiki dan perkhidmatan kecil yang serupa dengannya) dan
            individu yang ingin membeli barang dan perkhidmatan dari kawasan
            setempat. Anda boleh menggunakan Laman hanya untuk tujuan yang sah
            dalam konteks yang dinyatakan dari penggunaan Laman web yang sesuai
            dan boleh diterima oleh <b>meniaga</b>. <b>meniaga</b> adalah satu-satunya penaksir
            atas maksud penggunaan yang boleh diterima oleh Laman web ini.<br/><br/>

            <u>Lesen untuk digunakan oleh Pengguna yang merupakan Pembeli</u><br/>
            <b>meniaga</b> dengan ini memberikan anda hak terhad yang boleh ditamatkan
            dan tidak eksklusif untuk mengakses dan menggunakan Laman hanya
            untuk kegunaan peribadi anda membeli. Ini memberi kebenaran kepada
            anda untuk melihat dan memuat turun satu salinan bahan di Laman ini
            semata-mata untuk kegunaan peribadi dan bukan komersial anda. Anda
            bersetuju bahawa anda bertanggungjawab sepenuhnya terhadap kandungan
            Dokumen yang anda hantar ke Laman web dan apa-apa akibat yang timbul
            daripada pengeposan tersebut. Penggunaan Laman web oleh anda adalah
            satu keistimewaan. <b>meniaga</b> berhak untuk menangguhkan atau
            menghentikan hak istimewa tersebut dengan apa pun alasan pada bila-bila
            masa, mengikut budi bicara mutlaknya.<br/><br/>

            <u>Lesen untuk digunakan oleh Pengguna yang merupakan Peniaga</u><br/>
            <b>meniaga</b> dengan ini memberikan anda hak terhad yang boleh ditamatkan
            dan tidak eksklusif untuk mengakses dan menggunakan Laman hanya
            untuk penggunaan menjual barang (makanan, makanan basah, ternakan, sayuran dan produk kecil yang serupa dengannya) dan perkhidmatan (kecil-
            kecilan seperti mengambil upah, menjahit, membaiki dan perkhidmatan
            kecil yang serupa dengannya). Ini membenarkan anda melihat dan memuat
            turun satu salinan bahan di Laman ini semata-mata untuk kegunaan peribadi
            anda yang berkaitan secara langsung dengan menjual barang dan
            perkhidmatan. <b>meniaga</b> juga memberi anda lesen terhad yang boleh
            ditamatkan dan tidak eksklusif untuk menggunakan Bahan dan
            Perkhidmatan <b>meniaga</b> untuk kegunaan persendirian anda sahaja. Anda
            tidak boleh menjual, memindahkan atau menyerahkan mana-mana
            Perkhidmatan atau hak anda kepada mana-mana Perkhidmatan yang
            disediakan oleh <b>meniaga</b> kepada pihak ketiga tanpa kebenaran bertulis
            daripada <b>meniaga</b>. Anda bersetuju bahawa anda bertanggungjawab
            sepenuhnya terhadap kandungan Dokumen yang anda hantar ke Laman web
            dan apa-apa akibat yang timbul daripada pengeposan
            tersebut. <b>meniaga</b> berhak untuk menangguhkan atau menghentikan akses
            dan penggunaan anda pada bila-bila masa sekiranya <b>meniaga</b> menentukan
            bahawa anda melanggar Terma dan Syarat ini.<br/><br/>

            <u>Bahan Peniaga</u><br/>
            Peniaga menyatakan, menjamin, dan memberi perjanjian bahawa setiap
            Bahan Peniaga yang disediakan oleh Peniaga untuk digunakan sehubungan
            dengan Perkhidmatan tidak akan melanggar undang-undang atau peraturan
            atau hak milik pihak ketiga, termasuk, tanpa batasan, hak cipta, tanda
            dagang, kandungan peribadi, hak sulit atau kerahsiaan, dan tidak melanggar
            undang-undang fitnah. Peniaga dengan ini memberikan <b>meniaga</b> lesen
            bebas royalti tanpa eksklusif di seluruh dunia untuk menggunakan Bahan
            Peniaga dan untuk membuat pautan ke laman web Peniaga yang berkaitan
            dengan Perkhidmatan.<br/><br/>

            <u>Penggunaan Data</u><br/>
            Anda memahami dan bersetuju bahawa <b>meniaga</b> memiliki dan berhak
            mengumpulkan, mengekstrak, menyusun, mensintesis,
            dan menganalisis data, yang merangkumi data agregat dan tidak
            terkumpul. <b>meniaga</b> boleh menggunakan data tersebut untuk tujuan
            perniagaan yang sah tanpa ada kewajipan kepada anda. "Data
            Agregat" bermaksud data agregat yang tidak dikenal pasti atau mengenai
            pola jual beli yang telah dinyah-identiti (termasuk, sebagai contoh dan bukan
            batasan, data agregat yang berkaitan dengan jenis barang, lokasi, bayaran
            dan rekod Pengguna).<br/><br/>

            <u>Maklum Balas Pengguna</u><br/>
            <b>meniaga</b> mengalu-alukan komen anda mengenai Perkhidmatan dan Laman
            web dan menghargai maklum balas anda. Namun, <b>meniaga</b> berhak untuk
            tidak melayan, tidak menerima atau mempertimbangkan idea,
            cadangan, penemuan atau bahan kreatif selain daripada yang diminta secara
            khusus. Sekiranya anda mengemukakan maklum balas mengenai
            Perkhidmatan, sila beri komen anda dengan jelas dan bukannya untuk
            menyampaikan idea, penemuan, cadangan, atau bahan kreatif. Jika,
            walaupun dengan notis ini, anda menghantar kepada <b>meniaga</b> cadangan
            kreatif, idea-idea, lukisan, konsep, reka cipta, atau maklumat lain ("Maklum
            Balas Pengguna"), anda memahami dan bersetuju bahawa Maklum Balas
            Pengguna hendaklah menjadi harta <b>meniaga</b>. Maklum Balas Pengguna dan
            unsur-unsur kandungan yang terdapat dalam Maklum Balas Pengguna, tidak
            tertakluk kepada tanggungjawab kerahsiaan dari pihak <b>meniaga</b>,
            dan <b>meniaga</b> tidak akan bertanggungjawab atas penggunaan atau
            pendedahan Maklum Balas Pengguna. <b>meniaga</b> akan secara eksklusif
            memiliki semua hak yang diketahui sekarang atau yang diketahui kemudian
            bersabit Maklum Balas Pengguna dan berhak atas penggunaan Maklum Balas Pengguna tanpa had untuk apa-apa tujuan, komersial atau sebaliknya,
            tanpa pampasan kepada anda atau orang lain.<br/><br/>

            <u>Pembekal Pihak Ketiga</u><br/>
            Anda mengakui dan bersetuju bahawa <b>meniaga</b> berhak untuk menyediakan
            Perkhidmatan menggunakan pihak ketiga, termasuk subkontraktor dan
            perunding ("Pembekal Pihak Ketiga"). Anda bersetuju bahawa, seperti
            perjanjian di antara <b>meniaga</b> dan Pembekal Pihak Ketiga, <b>meniaga</b> akan
            mempunyai tanggungjawab sepenuhnya untuk mengendalikan semua
            rundingan berhubung kontrak dan bayaran dengan Pembekal Pihak Ketiga.
          </p>  
        </div>

        <div className="mt-4">
          <h6 className="font-weight-bold text-raleway text-red-theme">Penafian Dan Batasan Tanggungjawab meniaga</h6>
          <p className="text-raleway medium text-blue-theme" style={{ textAlign: "justify" }}>
            <b>meniaga</b> tidak bertanggungjawab atas Dokumen yang dihantar oleh
            Pengguna dan tidak bertanggungjawab terhadap aktiviti, kecuaian atau
            kelakuan Pengguna yang lain. <b>meniaga</b> bertindak sebagai portal untuk
            penyebaran dan penerbitan maklumat yang dikemukakan oleh Pengguna
            dalam talian dan tidak mempunyai kewajipan untuk menyaring komunikasi
            atau maklumat terlebih dahulu dan tidak bertanggungjawab untuk
            menyaring atau memantau Dokumen yang dihantar oleh
            Pengguna. Sekiranya diberitahu oleh Pengguna Dokumen yang dipercayai
            tidak mematuhi Terma dan Syarat ini, <b>meniaga</b> dapat menyiasat tuduhan
            tersebut dan menentukan dengan berniat baik dan atas budi bicara
            mutlaknya sama ada untuk membuang atau meminta penghapusan
            Dokumen tersebut. <b>meniaga</b> tidak mempunyai tanggungjawab atau
            bertanggungjawab kepada Pengguna ke atas pelaksanaan (atau tidak
            dilaksanakan) aktiviti tersebut. <b>meniaga</b> boleh mengambil tindakan
            berhubung Pengguna yang mengemukakan maklumat yang difikirkan perlu
            atau sesuai, mengikut budi bicara mutlaknya. Tiada apa pun di Laman web yang akan dianggap sebagai sokongan,
            perwakilan atau jaminan kepada atau bagi pihak Pengguna atau pihak ketiga
            mana pun, sama ada berkenaan dengan laman web, produk, perkhidmatan,
            jual beli, atau sebaliknya.
          </p>  
        </div>

        <div className="mt-4">
          <h6 className="font-weight-bold text-raleway text-red-theme">Penafian Jaminan</h6>
          <div className="text-raleway medium text-blue-theme" style={{ textAlign: "justify" }}>
            <b>(a)</b> LAMAN DISEDIAKAN DENGAN ASAS &#39;SEBAGAIMANA ADANYA&#39; TANPA
            JAMINAN SETIAP JENIS, TERSURAT ATAU TERSIRAT. <b>meniaga</b>, HINGGA
            YANG DIBENARKAN OLEH UNDANG-UNDANG, MENGECUALIKAN
            SEMUA JAMINAN, TERMASUK, TETAPI TIDAK TERHAD KEPADA,
            JAMINAN BOLEH DAGANG, HAKMILIK, KETIDAKLANGGARAN HAK
            PIHAK KETIGA, DAN JAMIN KESESUAIAN BAGI SESUATU
            TUJUAN. <b>meniaga</b> TIDAK MEMBERIKAN JAMINAN MENGENAI
            KETEPATAN, KEBOLEHPERCAYAAN, KESEMPURNAAN, ATAU TEPAT
            WAKTU LAMAN.<br/><br/>
            
            <b>(b)</b> TANPA BATASAN BAGI PERKARA BERIKUT:<br/>
            <div className="ml-4">
              <b>(i)</b> <b>meniaga</b> TIDAK MENJAMIN BAHAWA LAMAN AKAN BEROPERASI
              BEBAS DARI KECACATAN, KESALAHAN ATAU LAMAN DAN
              PERKHIDMATAN BEBAS DARI VIRUS KOMPUTER ATAU SEBARANG
              ANCAMAN. JIKA PENGGUNAAN LAMAN OLEH ANDA MENYEBABKAN
              SECARA LANGSUNG ATAU TIDAK LANGSUNG, ANDA PERLU
              MENGGANTIKAN ATAU MEMBAIKI PERALATAN ATAU
              DATA, <b>meniaga</b> TIDAK BERTANGGUNGJAWAB UNTUK MENANGGUNG
              KOS TERSEBUT.<br/>

              <b>(ii)</b> <b>meniaga</b> tidak membuat pernyataan atau jaminan mengenai kebenaran,
              ketepatan, KEABSAHAN, kelengkapan, ketepatan masa atau kebolehpercayaan ke atas setiap Dokumen yang dihantar oleh Pengguna,
              atau bentuk komunikasi lain yang dilakukan oleh Pengguna. Dokumen
              mungkin mengandungi ketidaktepatan atau kesalahan tipografi. Anda
              bersetuju bahawa sebarang pergantungan pada Dokumen yang dihantar oleh
              Pengguna, atau pada bentuk komunikasi lain dengan Pengguna, adalah atas
              risiko anda sendiri.<br/>

              <b>(iii)</b> <b>meniaga</b> tidak membuat pernyataan atau jaminan mengenai
              Kandungan Laman, termasuk, tetapi tidak terbatas pada, pautan yang
              terputus, ketidaktepatan atau kesalahan tipografi.<br/>

              <b>(iv)</b> <b>meniaga</b> TIDAK MEMBUAT PERWAKILAN ATAU JAMINAN TENTANG
              KEBERKESANAN PERKHIDMATAN ATAU TEPAT WAKTU PERKHIDMATAN DALAM MEMENUHI OBJEKTIF JUAL BELI ANDA. 
              <b>meniaga</b> TIDAK MENJAMIN BAHAWA PERKHIDMATAN AKAN
              MENGHASILKAN JUAL BELI SEPERTI YANG DIKEHENDAKI PENGGUNA,
              DAN TIDAK BERTANGGUNGJAWAB ATAU BERTANGGUNGJAWAB BAGI
              SETIAP URUS NIAGA, HARGA, MUTU, KELEWATAN PENGHANTARAN,
              KECUAIAN, PENGGUNA YANG INGKAR DALAM URUS NIAGA, YANG
              KEPUTUSANNYA DIBUAT SENDIRI OLEH ANDA.
            </div>
          </div>  
        </div>

        <div className="mt-4">
          <h6 className="font-weight-bold text-raleway text-red-theme">Pembatasan Kerosakan, Agihan Tanggungjawab Dan Pelepasan Saksama</h6>
          <p className="text-raleway medium text-blue-theme" style={{ textAlign: "justify" }}>
            <b>(a)</b> ANDA MENGAMBIL SEMUA TANGGUNGJAWAB DAN RISIKO UNTUK
            PENGGUNAAN LAMAN ANDA, INTERNET SECARA UMUM, DAN
            DOKUMEN ATAU BAHAN YANG ANDA HANTAR, SEDIAKAN ATAU AKSES
            DAN UNTUK PERTIMBANGAN ANDA DI ATAS TALIAN DAN LUAR TALIAN.<br/><br/>
            
            <b>(b)</b> TIDAK ADA APA KEADAAN YANG MENYEBABKAN <b>meniaga</b> (ATAU
            SETIAP PEGAWAI, PENGARAH, PEMEGANG SAHAM, PEKERJA,
            SUBSIDIARI, SYARIKAT BERSEKUTU, EJEN ATAU PENGIKLAN),
            BERTANGGUNGJAWAB TERHADAP KERUGIAN TIDAK LANGSUNG
            (TERMASUK, TANPA BATASAN, HILANG KEUNTUNGAN, ATAU
            KERUGIAN YANG BERHASIL DARI DATA YANG HILANG, URUS NIAGA
            YANG HILANG, ATAU GANGGUAN PERNIAGAAN) TERHASIL DARI ATAU
            BERHUBUNG DENGAN PERKHIDMATAN ATAU PENGGUNAAN ATAU AKSES, SAMA ADA BERSANDARKAN JAMINAN, KONTRAK, TORT,
            ATAU APA-APA TEORI UNDANG-UNDANG LAIN, TIDAK KIRA SAMA ADA <b>meniaga</b> TELAH DINASIHATKAN MENGENAI KEMUNGKINAN
            KEROSAKAN SEPERTI ITU.<br/><br/>
            
            <b>(c)</b> OLEH KERANA BEBERAPA NEGERI ATAU JURISDIKSI TIDAK
            MEMBENARKAN PENGECUALIAN ATAU PEMBATASAN
            TANGGUNGJAWAB AKIBAT KEROSAKAN TIDAK LANGSUNG, BATASAN
            YANG DITETAPKAN DALAM PERENGGAN SEBELUM INI MUNGKIN TIDAK
            TERPAKAI KEPADA ANDA.<br/><br/>
            
            <b>(d)</b> Kerana sifat Perjanjian ini, selain ganti rugi wang, anda bersetuju
            bahawa <b>meniaga</b> akan berhak mendapat pelepasan yang setimpal apabila
            anda melanggar perjanjian ini, dari anda.
          </p>  
        </div>

        <div className="mt-4">
          <h6 className="font-weight-bold text-raleway text-red-theme">Pengesahan Pengguna</h6>
          <p className="text-raleway medium text-blue-theme" style={{ textAlign: "justify" }}>
            Oleh kerana pengesahan Pengguna di Internet sukar, <b>meniaga</b> tidak dapat
            dan tidak mengesahkan bahawa setiap Pengguna adalah siapa yang mereka
            mengaku, walaupun <b>meniaga</b> berjanji untuk mengesahkan Pengguna
            berdasarkan usaha yang wajar. Oleh kerana <b>meniaga</b> tidak dan tidak boleh
            terlibat dalam urusan Pengguna-ke-Pengguna atau mengawal tingkah
            laku peserta di Laman web, sekiranya anda mempunyai perselisihan dengan satu atau lebih Pengguna, anda melepaskan <b>meniaga</b> dari tuntutan dan
            kerosakan (sebenar dan berurutan; langsung dan tidak langsung) dari segala
            jenis dan sifat, yang diketahui dan tidak diketahui, disyaki dan tidak disyaki,
            didedahkan dan tidak didedahkan, timbul dari atau dengan cara apa pun
            yang berkaitan dengan pertikaian tersebut.
          </p>  
        </div>

        <div className="mt-4">
          <h6 className="font-weight-bold text-raleway text-red-theme">Hubungan Ke Laman Lain</h6>
          <p className="text-raleway medium text-blue-theme" style={{ textAlign: "justify" }}>
            Laman web <b>meniaga</b> mengandungi pautan ke laman web pihak
            ketiga. Pautan ini disediakan hanya untuk kemudahan anda dan bukan
            sebagai sokongan oleh <b>meniaga</b> kepada kandungan di laman web pihak
            ketiga tersebut. <b>meniaga</b> tidak bertanggungjawab untuk kandungan laman
            web pihak ketiga yang dipautkan dan tidak membuat pernyataan mengenai
            kandungan atau ketepatan bahan di laman web pihak ketiga
            tersebut. Sekiranya anda memutuskan untuk mengakses laman web pihak
            ketiga yang dipautkan, anda melakukannya dengan risiko anda sendiri.
          </p>  
        </div>

        <div className="mt-4">
          <h6 className="font-weight-bold text-raleway text-red-theme">Pindaan Kepada Perjanjian Ini Dan Perubahan Ke Laman</h6>
          <p className="text-raleway medium text-blue-theme" style={{ textAlign: "justify" }}>
            <b>meniaga</b>  berhak mengubah Terma dan Syarat ini pada bila-bila masa
            dengan mengemas kini halaman ini dan halaman lain yang berkaitan yang
            menerbitkan Perjanjian. Perubahan akan mengikat anda pada tarikh ia
            disiarkan di Laman web (atau sebagaimana dinyatakan sebaliknya dalam
            pemberitahuan perubahan tersebut). Sebarang penggunaan Laman web
            akan dianggap penerimaan oleh anda dari Terma dan Syarat semasa
            (termasuk pameran di dalamnya) yang terdapat di Laman
            tersebut. Sekiranya pada bila-bila masa anda mendapati Terma dan Syarat
            tidak dapat diterima, anda tidak boleh menggunakan Laman web yang
            berkenaan lagi. Apa-apa syarat baru atau berbeza yang anda berikan secara
            khusus ditolak oleh <b>meniaga</b> melainkan <b>meniaga</b> bersetuju secara bertulis dan khusus termasuk meliputi syarat-syarat baru atau
            berbeza. <b>meniaga</b> boleh mengubah Laman web pada bila-bila masa.
          </p>  
        </div>

        <div className="mt-4">
          <h6 className="font-weight-bold text-raleway text-red-theme">Maklumat Pengguna Dan Dasar Privasi</h6>
          <p className="text-raleway medium text-blue-theme" style={{ textAlign: "justify" }}>
            Apabila anda mendaftar di mana-mana Laman <b>meniaga</b> atau berkaitan
            dengan Perkhidmatan, anda akan diminta untuk membuat akaun dan
            memberikan maklumat tertentu kepada <b>meniaga</b> , termasuk tetapi tidak
            terhad kepada, alamat e-mel yang sah (&quot;Maklumat Pengguna&quot;). Anda
            mengakui dan bersetuju bahawa anda tidak mempunyai hak pemilikan
            kepada akaun <b>meniaga</b> anda.<br/><br/>
            
            Semua Maklumat Pengguna akan digunakan sesuai dengan syarat-syarat Dasar Privasi <b>meniaga</b>. 
            Sila maklum, sebagaimana dinyatakan dalam
            Dasar Privasi, bahawa <b>meniaga</b> berhak mengumpulkan Maklumat
            Pengguna tertentu dan dapat menghubungi Pengguna secara berkala sesuai
            dengan ketentuan dalam Dasar Privasi. Di samping itu, <b>meniaga</b> berhak
            untuk mematuhi, menurut budi bicara mutlaknya, dengan keperluan
            undang-undang, permintaan dari agensi penguatkuasaan atau permintaan
            dari badan pemerintah, bahkan sekiranya pematuhan tersebut mungkin
            memerlukan Maklumat Pengguna tertentu didedahkan kepada badan
            berkenaan. Di samping itu, pihak ketiga boleh menyimpan salinan cache
            Maklumat Pengguna.
          </p>  
        </div>

        <div className="mt-4">
          <h6 className="font-weight-bold text-raleway text-red-theme">Umum</h6>
          <p className="text-raleway medium text-blue-theme" style={{ textAlign: "justify" }}>
            Maklumat untuk menghubungi <b>meniaga</b> disenaraikan di
            Laman. <b>meniaga</b> tidak menyatakan bahawa Kandungan sesuai atau boleh
            dimuat turun di luar Malaysia. Akses ke Kandungan mungkin tidak sah oleh
            orang-orang tertentu atau di negara-negara tertentu, dan orang-orang
            tersebut tidak memiliki hak untuk mengakses atau menggunakan Laman web. 
            Sekiranya anda mengakses <b>meniaga</b> dari luar Malaysia, anda
            melakukannya dengan menanggung risiko anda sendiri dan
            bertanggungjawab untuk mematuhi undang-undang bidang kuasa
            anda. Perjanjian ini dan Perjanjian Pengaktifan Perkhidmatan anda, jika ada,
            diatur mengikut dan tertakluk kepada undang-undang Malaysia, tanpa
            mengambil kira konflik prinsip undang-undangnya. Bidang kuasa untuk
            sebarang tuntutan yang timbul di bawah Perjanjian ini atau Perjanjian
            Pengaktifan Perkhidmatan anda akan terletak sepenuhnya di mahkamah
            Malaysia. Satu-satunya hubungan antara anda dan <b>meniaga</b> adalah
            hubungan kontraktor bebas. Sekiranya mana-mana peruntukan Perjanjian
            ini didapati tidak sah oleh mana-mana mahkamah yang mempunyai bidang
            kuasa yang kompeten, ketidaksahan semua atau sebahagian peruntukan
            tidak akan mempengaruhi kesahihan bahagian dan peruntukan Perjanjian
            ini yang masih ada, yang akan tetap berkuatkuasa dan berkuat kuasa
            sepenuhnya . Semua peruntukan Perjanjian ini akan terus terpakai selepas
            penamatan kecuali yang memberikan akses atau penggunaan ke Laman
            web, dan anda akan menghentikan semua penggunaan dan aksesnya dengan
            segera. Anda tidak boleh menyerahkan atau memindahkan tanggungjawab
            anda di bawah Perjanjian ini. Tiada pengecualian mana-mana terma
            Perjanjian ini akan dianggap sebagai pengabaian selanjutnya atau
            berlanjutan dari terma tersebut atau istilah lain. Kecuali sebagaimana yang
            dinyatakan secara terang oleh <b>meniaga</b> dalam "Pemberitahuan Undang-Undang" 
            tertentu atau bahan di laman web tertentu di Laman, Perjanjian ini
            dan Perjanjian Pengaktifan Perkhidmatan anda jika berlaku, merupakan
            keseluruhan perjanjian antara anda dan <b>meniaga</b>.
          </p>  
        </div>

        <div className="mt-4">
          <h6 className="font-weight-bold text-raleway text-red-theme">Peraturan Tentang Pengeposan Kandungan, Tingkah Laku Dan Keselamatan Sesawang</h6>
          <p className="text-raleway medium text-blue-theme" style={{ textAlign: "justify" }}>
            <u>Peraturan Pengeposan</u><br/>
            <b>(a)</b> Anda tidak boleh mengeposkan atau memuatnaik Dokumen apa pun ke
            Laman yang mempunyai: <b>(i)</b> URL atau pautan ke laman web selain untuk
            mengiklankan barang dan perkhidmatan yang menggunakan platform <b>meniaga</b>; 
            <b>(ii)</b> bahan berhak cipta (melainkan jika anda memiliki hak cipta
            atau mempunyai kebenaran pemilik untuk menghantar bahan berhak
            cipta); <b>(iii)</b> rahsia perdagangan (melainkan jika anda memilikinya atau
            mempunyai kebenaran pemilik untuk menyiarkannya); <b>(iv)</b> bahan yang
            melanggar atau menyalahgunakan hak harta intelek lain, atau melanggar
            hak privasi atau publisiti orang lain; <b>(v)</b> kata kunci yang tidak relevan atau
            kata kunci teks putih (termasuk kata-kata yang tertanam dalam Dokumen
            dan disembunyikan dari Pengguna); <b>(vi)</b> apa-apa yang bersifat diskriminatif,
            seksual, cabul, fitnah, fitnah mengancam, mengganggu, kasar, atau
            menimbulkan kebencian; atau <b>(vii)</b> apa-apa yang memalukan atau
            menyinggung perasaan orang atau entiti lain.<br/>
            <b>(b)</b> Anda tidak boleh menggunakan Dokumen untuk: <b>(i)</b> menyamar sebagai
            orang lain, hidup atau mati; <b>(ii)</b> menghantar maklumat, pendapat atau notis
            palsu, tidak tepat atau mengelirukan (komersial atau sebaliknya) atau surat
            berantai; <b>(iii)</b> menyiarkan iklan, atau permintaan perniagaan (termasuk,
            tetapi tidak terhad kepada, pemproses e-mel, skim piramid atau "keahlian
            kelab").<br/>
            <b>(c)</b> Dokumen anda mesti mengandungi perincian yang mencukupi untuk
            menyampaikan dengan jelas kepada Pengguna mengenai sifat dan harga
            barang dan perkhidmatan yang diniagakan di platform <b>meniaga</b>. Dokumen
            yang mendorong Pengguna untuk "menghantar e-mel untuk maklumat lebih
            lanjut" tidak dibenarkan.<br/>
            <b>(d)</b> <b>meniaga</b> tidak mempunyai kewajipan untuk memantau Dokumen yang
            dipos di Laman, tetapi mungkin memantau Dokumen secara
            rawak. Dokumen yang didapati melanggar Peraturan Pengeposan di atas
            boleh dikeluarkan mengikut budi bicara <b>meniaga</b>.<br/><br/>

            <u>Peraturan Perlakuan</u><br/>
            <b>(a)</b> Anda tidak boleh bertindak atau memberi maklum balas ke atas sesuatu
            pos oleh Pengguna lain dengan cara atau untuk tujuan selain daripada yang
            ditetapkan atau dibenarkan <b>meniaga</b> (iaitu untuk urusniaga jual beli barang
            dan perkhidmatan kecil-kecilan).<br/>
            <b>(b)</b> Anda tidak boleh menghantar e-mel komersial yang tidak diminta
            Pengguna kepada Pengguna.<br/>
            <b>(c)</b> Lindungi kata laluan anda. Akaun <b>meniaga</b> anda dan mana-mana
            Perkhidmatan yang berkaitan diakses melalui akaun tersebut dan
            dikhususkan untuk diakses dan digunakan sepenuhnya oleh anda. Setelah
            berjaya mendaftar untuk akaun <b>meniaga</b>, anda akan diberikan kata laluan
            unik yang akan membolehkan anda mengakses akaun tersebut. Anda tidak
            boleh memberikan kata laluan anda atau membenarkan akses
            ke akaun <b>meniaga</b> anda kepada pihak ketiga mana pun. Anda
            bertanggungjawab untuk menjaga kerahsiaan maklumat dan kata laluan
            anda. Anda bersetuju untuk melindungi dan menjaga kerahsiaan kata laluan
            anda, untuk menukar kata laluan anda secara berkala, dan untuk
            mengekalkan alat pembersihan perisian hasad yang sesuai dan dikemas kini
            secara berkala di rangkaian dan komputer individu anda. Anda
            bertanggungjawab untuk semua penggunaan akaun anda, sama ada
            dibenarkan atau tidak oleh anda. Sekiranya orang lain menggunakan kata
            laluan anda untuk menyiarkan bahan yang tidak sesuai di Laman, anda
            berisiko kehilangan akses ke Laman. Anda bersetuju untuk segera memberitahu <b>meniaga</b> mengenai penggunaan akaun atau
            kata laluan anda secara tidak sah.<br/>
            <b>(d)</b> Laporkan penyiaran atau perlakuan yang tidak sesuai kepada kami
            dengan kaedah yang ada.<br/>
            <b>(e)</b> Anda tidak boleh menghapus atau menyemak semula sebarang bahan
            yang diposkan oleh orang atau entiti lain.<br/>
            <b>(f)</b> Sekiranya pada bila-bila masa <b>meniaga</b> memahami bahawa anda: <b>(i)</b>
            tidak memberikan maklumat yang tepat kepada <b>meniaga</b> mengenai amalan
            perniagaan dan/atau barang/perkhidmatan anda, atau <b>(ii)</b> membeli
            perkhidmatan yang tidak mewakili perniagaan anda yang
            tepat, <b>meniaga</b> berhak untuk menghentikan Perjanjian.<br/><br/>
            
            <u>Peraturan Keselamatan</u><br/>
            <b>(a)</b> Pengguna dilarang melanggar atau mencuba melanggar keselamatan
            Laman, termasuk, tanpa batasan: <b>(i)</b> mengakses data yang tidak
            dimaksudkan untuk Pengguna tersebut atau masuk ke pelayan atau akaun
            yang tidak diizinkan oleh Pengguna untuk mengakses; <b>(ii)</b> cuba menyiasat,
            mengimbas atau menguji kerentanan sistem atau rangkaian atau melanggar
            langkah keselamatan atau pengesahan tanpa kebenaran yang betul; <b>(iii)</b> cuba
            mengganggu perkhidmatan kepada Pengguna, hos atau rangkaian mana
            pun, termasuk, tanpa batasan, melalui cara mengirimkan virus ke Laman,
            kelebihan beban, "membanjirkan", "pengeboman mel", atau "melumpuhkan 
            laman"; <b>(iv)</b> menghantar e-mel yang tidak diminta, termasuk promosi dan/atau 
            pengiklanan barang atau perkhidmatan; <b>(v)</b> memalsukan sebarang
            “header packet” TCP/IP atau mana-mana bahagian maklumat “header”
            dalam sebarang pengeposan e-mel atau kumpulan berita.<br/>
            <b>(b)</b> Pelanggaran Peraturan Keselamatan ini boleh mengakibatkan
            tanggungan sivil atau jenayah. <b>meniaga</b> akan menyiasat kejadian yang
            mungkin melibatkan pelanggaran tersebut dan mungkin melibatkan, dan
            bekerjasama dengan, pihak berwajib dalam mendakwa Pengguna yang
            terlibat dalam pelanggaran tersebut.    
          </p>  
        </div>

        <div className="mt-4">
          <h6 className="font-weight-bold text-raleway text-red-theme">Aduan Hak Cipta</h6>
          <p className="text-raleway medium text-blue-theme" style={{ textAlign: "justify" }}>
            <b>meniaga</b> menghormati harta intelek orang lain. <b>meniaga</b> akan memberi
            maklum balas terhadap tuntutan hak cipta dan pelanggaran harta intelek
            lain pada kadar yang berpatutan. <b>meniaga</b> akan segera memproses dan
            menyiasat aduan mengenai dakwaan pelanggaran dan akan mengambil
            tindakan yang sesuai tertakluk kepada peruntukan undang-undang harta
            intelek. Setelah menerima sebarang pemberitahuan yang
            sah, <b>meniaga</b> akan bertindak dengan cepat untuk membuang atau
            mematikan akses ke sebarang bahan yang didakwa melanggar atau didakwa
            sebagai subjek aktiviti yang melanggar dan dapat bertindak dengan cepat
            untuk menghapus atau mematikan akses ke rujukan atau pautan ke bahan
            atau aktiviti apa pun yang didakwa melanggar. <b>meniaga</b> akan menghentikan
            akses untuk Pengguna yang merupakan pelanggar berulang.
          </p>  
        </div>

      </div>
    </div>
  );
}

export default TermsAndConditions;