import React from 'react';

import MeniagaLight from "../assets/meniaga-icon-lightmode.png";

function PaymentGatewayTermsAndConditions() {
  return (
    <div className="privacy-terms-conditions">
      <div className="desktop-view">
        <div className="row header" style={{ lineHeight: "10vh" }}>
          <a style={{ width: "180px", paddingLeft: "15px" }} href="/">
            <img src={MeniagaLight} className="meniaga-icon" alt="meniagaIcon" />
          </a>
          <div className="col-md-3">
            <p className="small text-raleway font-italic h-100 pt-1" style={{ color: '#00334C' }}>Pengantara Dagang Percuma</p>
          </div>
        </div>
      </div>
      
      <div className="mobile-view" style={{ margin: "auto" }}>
        <a href="/">
          <img src={MeniagaLight} className="meniaga-icon" alt="meniagaIcon" />
        </a>
        <p className="small text-raleway font-italic h-100 pt-1" style={{ color: '#00334C' }}>Pengantara Dagang Percuma</p>
      </div>

      <div className="row content">
        <h4 className="font-weight-bold text-raleway text-red-theme">TERMA DAN SYARAT PLATFORM PEMBAYARAN DAN PENGHANTARAN</h4><br/>    

        <div className="mt-4">
          <h6 className="font-weight-bold text-raleway text-red-theme">1. Terma dan Syarat Umum Platform Pembayaran</h6>
          <p className="text-raleway medium text-blue-theme" style={{ textAlign: "justify" }}>
          1.1	    Anda perlu mendaftar akaun bersama <b>meniaga</b> sebelum menggunakan platform ini. Ketika pendaftaran akaun bersama <b>meniaga</b>, maklumat peribadi seperti alamat emel yang sah, nombor telefon bimbit dan kata laluan yang unik perlu dinyatakan. Melainkan dalam kes frod atau penyalahgunaan, anda mengakui bahawa setiap Pesanan yang dilakukan di bawah akaun <b>meniaga</b> adalah tanggungjawab anda sepenuhnya.
          <br/><br/>
          1.2	    <b>meniaga</b> merizabkan hak untuk menawarkan kaedah pembayaran tambahan dan/atau atau mengeluarkan kaedah pembayaran sedia ada pada setiap masa mengikut budi bicara dan keputusan <b>meniaga</b>. Sekiranya Pembeli memilih untuk membayar secara pembayaran online, bayaran tersebut akan diproses oleh penyedia perkhidmatan pihak ketiga.
          <br/><br/>
          1.3	    <b>meniaga</b> berhak untuk menggantung atau menghentikan sebarang pemprosesan transaksi sekiranya memiliki alasan yang kukuh bahawa sesuatu transaksi tersebut adalah haram, tidak sah di sisi undang-undang atau membabitkan sebarang aktiviti jenayah di mana anda dan/atau Pengguna telah melanggar Terma dalam perjanjian ini. Dalam keadaan ini, <b>meniaga</b> tidak akan bertanggungan untuk sebarang liabiliti untuk menangguh, menarik atau membatalkan sebarang bayaran kepada anda.
          <br/><br/>
          1.4	    <b>meniaga</b> akan mengubah terma-terma ini mengikut budi bicara dan keperluan. Terma-terma yang dipinda ini akan berkuatkuasa serta-merta sebaik sahaja dimuat naik dan anda menyatakan persetujuan kepada kepada terma-terma baru ini dengan penggunaan berterusan platform ini. Anda bertanggungjawab sepenuhnya untuk sentiasa menyemak terma-terma dari masa ke masa. Sekiranya anda tidak bersetuju dengan dengan pindaan terma-terma, sama ada secara sebahagian atau keseluruhan, anda harus terus berhenti menggunakan platform ini dengan segera. 
          <br/><br/>
          1.5     Caj penghantaran akan dicaj untuk setiap Pesanan dan akan dikenakan kepada Pembeli melainkan:
          Pembeli memilih untuk mengambil sendiri Pesanan daripada Peniaga (“Ambil Sendiri”); atau
          melainkan dinyatakan sebaliknya.
          <br/><br/>          
          1.6	    Harga yang dinyatakan di Platform adalah sama ketika Pesanan dibuat dan tertakluk kepada sebarang perubahan.
          <br/><br/>          
          1.7     Sebagai Peniaga berdaftar bersama <b>meniaga</b>, anda boleh memilih kaedah pembayaran yang ditawarkan termasuklah:
          <ol className="text-raleway text-blue-theme" type="a">
          <li className="text-raleway">)  FPX; </li>
          <li className="text-raleway">)  Cash-on-Delivery; atau </li> 
          <li className="text-raleway">)  Kaedah-kaedah lain yang akan ditawarkan dan diumumkan dari masa ke masa. </li>  
          </ol>        
          </p>  
        </div>

        <div className="mt-4">
          <h6 className="font-weight-bold text-raleway text-red-theme">2. Fi dan Bayaran</h6>
          <p className="text-raleway medium text-blue-theme" style={{ textAlign: "justify" }}>

          Anda mengakui dan mengesahkan bahawa <b>meniaga</b> akan mentadbir dan bertindak sebagai pihak yang mengutip dan menerima bayaran untuk membayar anda jumlah keseluruhan transaksi daripada Pesanan yang diterima daripada pengguna sebagai menggunakan Platform ini.
          <br/><br/>
          2.1     Fi
          <br/><br/>
          2.1.1	    Pada ketika Terma dan Syarat ini ditulis, <b>meniaga</b> tidak mengenakan sebarang caj atau fi kepada Peniaga yang menggunakan <b>meniaga</b>.. Sebarang perubahan akan dimaklum di platform ini dan akan berkuat kuasa serta merta.
          <br/><br/>
          2.2     Terma Bayaran
          <br/><br/>
          <u>Fi Transaksi</u>
          <br/><br/>
          2.2.1	    <b>meniaga</b> mengenakan fi transaksi kepada setiap transaksi yang berjaya di atas platform. Fi ini akan ditanggung oleh Pembeli sebanyak 1%. Sebarang perubahan pada fi akan dipinda dan dimaklum pada platform ini dan akan berkuat kuasa serta merta
          <br/><br/>
          <u>Bayaran kepada Peniaga</u>
          <br/><br/>
          2.2.2	    <b>meniaga</b> akan memproses bayaran kepada Peniaga bagi setiap Pesanan yang berjaya dan diterima oleh Pembeli bagi transaksi yang diselesaikan secara bayaran online. Bayaran kepada Peniaga untuk setiap Pesanan yang berjaya dan diterima oleh Pembeli akan diproses dalam masa 4-10 hari bekerja tidak termasuk dengan hari transaksi berlaku. <b>meniaga</b> berhak menangguhkan bayaran melebihi daripada masa pemprosesan melebihi 10 hari sekiranya terdapat alasan yang munasabah.
          <br/><br/>          
          2.2.3	    Bagi setiap pembayaran yang berjaya daripada <b>meniaga</b> kepada Peniaga, Peniaga akan dimaklumkan menerusi emel.
          <br/><br/>
          2.2.4	    Bayaran kepada Peniaga hanyalah melibatkan harga makanan atau produk seperti yang dipamerkan di Platform dan tidak akan melibatkan pembayaran tambahan yang lain melainkan dinyatakan dan dimaklumkan oleh <b>meniaga</b>.
          <br/><br/>
          2.2.5	    <b>meniaga</b> tidak akan memproses bayaran yang melibatkan transaksi Cash on Delivery. Sebarang urusan pertikaian dan penyelesaian berkaitan pembayaran secara Cash on Delivery hanyalah membabitkan Peniaga dan Pembeli.
          <br/><br/>
          2.2.6	    Sekiranya terdapat sebarang kesilapan dalam pemprosesan pembayaran, <b>meniaga</b> akan mengambil tindakan sewajarnya berdasarkan undang-undang dan peruntukan sedia ada. Peniaga boleh menghubungi dan memaklumkan <b>meniaga</b> sekiranya berlaku kesilapan pada peringkat pemprosesan dan jumlah pembayaran. Sebarang keputusan berkenaan kesilapan dan/atau pertikaian pembayaran adalah muktamad        
          </p>  
        </div>

        <div className="mt-4">
          <h6 className="font-weight-bold text-raleway text-red-theme">3. Pembatalan, Pemulangan dan Pembayaran Semula</h6>
          <p className="text-raleway medium text-blue-theme" style={{ textAlign: "justify" }}>
            <u>Pesanan menerusi Bayaran Secara Online</u>
            <br/><br/>
            3.1     Pembeli berhak kepada pembayaran semula setelah membuat pesanan dan bayaran dalam keadaan berikut:
            <ol type="a">
              <li className="text-raleway">) Peniaga membatalkan pesanan yang telah diterima dan telah dibuat pembayaran oleh Pembeli;</li>
              <li className="text-raleway">) Pembeli tidak menerima pesanan setelah membayar.</li>
            </ol>
            <u>Pesanan menerusi Cash on Delivery</u>            
            <br/><br/>
            3.2     Sebarang transaksi yang membabitkan Cash on Delivery hanyalah membabitkan Peniaga dan Pembeli.
            <br/><br/>
            3.3     <b>meniaga</b> merizabkan hak untuk membatalkan sebarang Pesanan dan/atau menggantung atau menyahktifkan akaun <b>meniaga</b> anda sekiranya secara didapati terlibat dalam sebarang aktiviti dan kegiatan frod yang berkaitan dengan akaun <b>meniaga</b> dan/atau Pesanan.
          </p>
        </div>

        <div className="mt-4">
          <h6 className="font-weight-bold text-raleway text-red-theme">4. Maklumat Perbankan Peniaga</h6>
          <p className="text-raleway medium text-blue-theme" style={{ textAlign: "justify" }}>
            4.1 Peniaga boleh memilih menggunakan perkhidmatan pembayaran secara online di <b>meniaga</b> sama ada menerusi FPX, Kad Kredit dan kaedah-kaedah lain yang akan diumum dan ditawarkan dari masa ke masa.
            <br/><br/>
            4.2 Sekiranya Peniaga memilih untuk menggunakan perkhidmatan pembayaran secara online, Peniaga harus memasukkan maklumat perbankan bagi tujuan pembayaran semula kepada Peniaga daripada <b>meniaga</b> untuk setiap transaksi berjaya di atas platform <b>meniaga</b>.
            <br/><br/>
            4.3 Peniaga adalah bertanggungjawab sepenuhnya kepada kesahan maklumat perbankan yang diberikan. <b>meniaga</b> tidak akan bertanggungjawab kepada maklumat perbankan yang salah yang akan mengakibatkan kelambatan pembayaran semula kepada Peniaga.
            <br/><br/>
            4.4 Peniaga adalah bertanggungjawab sepenuhnya untuk mengemaskini maklumat perbankan yang tepat dan terkini dari masa ke masa sekiranya berlaku perubahan. <b>meniaga</b> tidak akan bertanggungan untuk sebarang bayaran lewat kepada Peniaga disebabkan maklumat perbankan yang tidak lengkap atau tidak tepat.
          </p>
        </div>

      </div>
    </div>
  );
}

export default PaymentGatewayTermsAndConditions;