import React, { useState } from 'react';
import Header from './Header';
import FirstPage from './FirstPage';
import RamadhanPage from './RamadhanPage';
import AllianceBankPage from './AllianceBankPage';
import SecondPage from './SecondPage';
import ThirdPage from './ThirdPage';
import FourthPage from './FourthPage';
import Footer from './Footer';

import i18n from '../i18n.config';

function Home({parentSelected, parentSetSelected, parentHandleChange}) {
  // const [selected, setSelected] = useState(i18n.language == 'my' ? 'my' : 'en')

  // const handleChange = (event) => {
  //   if (event.target.value == 'my') {
  //     i18n.changeLanguage('my')
  //     setSelected('my')
  //   }
  //   else if (event.target.value == 'en') {
  //     i18n.changeLanguage('en')
  //     setSelected('en')
  //   }
  // }

  return (
    <div>
      <Header         
        parentSelected={parentSelected}
        parentSetSelected={parentSetSelected}
        parentHandleChange={parentHandleChange}
      />
      <FirstPage
        parentSelected={parentSelected}
        parentSetSelected={parentSetSelected}
        parentHandleChange={parentHandleChange}
      />
      <div id='ramadhan-section'>
        <RamadhanPage/>
      </div>
      <AllianceBankPage />
      <SecondPage />
      <ThirdPage />
      <FourthPage />
      {/* <Footer /> */}
    </div>
  );
}

export default Home;