import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import languageDetector from 'i18next-browser-languagedetector';
import { en, my } from "../src/i18n";
import HttpApi from 'i18next-http-backend';


const resources = {
  en: {
    translation: en,
  },
  my: {
    translation: my,
  },

};

i18n
.use(initReactI18next)
.use(languageDetector)
.use(HttpApi)
.init({
  resources, 
  supportedLngs: ['my', 'en'],
  fallbackLng: 'my', 
  detection: {
    order: ["cookie", 'my', "localStorage"], // if there is nothing stored in the cookie yet, the language will be 'my' which is the default language.
    caches: ['cookie']
  }, 
  backend: {
    loadPath: '/i18n/{{lng}}.json'
  }, 
  react: { useSuspense: false}, 
  debug: true

  }) 


export default i18n;
