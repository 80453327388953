import React from 'react';

import AllianceBankLogo from "../assets/AllianceBank/Alliance Logo.png";
import MeniagaLogo from "../assets/AllianceBank/Meniaga Logo.png";
import MeniagaGraph from "../assets/AllianceBank/graph.png";

import "../App.css";
import { ArrowForward } from '@material-ui/icons';
import i18n from '../i18n.config';

function DesktopView() {

    //submit to google sheet alliance
    function nextPage() {
        window.location.href = "/alliance_form";
    }
    return (
        <div id="alliance_page" className="alliance" style={{ justifyContent: "space-between" }}>

            <div style={{ display: "flex", flexDirection: "column", marginBottom: 20, padding: "0 100px 30px 100px" }}>
                <div className="row header">
                    <div className="col-md-12">
                        <img src={AllianceBankLogo} className="alliance-image" alt="alliance1" style={{ paddingBottom: 5 }} />
                        <img src={MeniagaLogo} className="meniaga-image" alt="alliance2" />
                    </div>
                </div>

                <div className="col-md-12" style={{ display: "flex", flexDirection: "row" }}>
                    <div className="col-md-6">
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div className="text-blue-theme text-raleway font-weight-bold mb-3" style={{ fontSize: "45px" }}>
                                {i18n.t('digitalPackageAndFinancing')}
                            </div>

                            <p className="text-blue-theme medium-message mb-5">
                                {i18n.t('enterpriseAndSme')}
                            </p>

                            {i18n.language == 'my' ?
                                <p className="small-message text-blue-theme" style={{ lineHeight: 2 }}>
                                    Mari terokai pakej digital dan pembiayaan khas untuk perniagaan
                                    dan perusahaan kecil dan sederhana (SME) di bawah komuniti <b><i>meniaga</i></b>.
                                    <br /><br />
                                    Kami ingin membantu meluaskan perniagaan anda kepada lebih ramai
                                    pelanggan, mendapat akses pembiayaan dan mendigitalkan perniagaan anda.
                                </p>
                                : <p className="small-message text-blue-theme" style={{ lineHeight: 2 }}>
                                    Explore the special packages and financing offered for businesses and small and medium enterprises (SMEs) under the business community.
                                    <br /><br />
                                    We want to help expand your business to more customers, gain access to bank financing and digitalize your business.
                                </p>

                            }

                        </div>
                    </div>

                    <div className="col-md-6">
                        <img src={MeniagaGraph} className="meniaga-graph-image" alt="alliance3" />
                    </div>
                </div>

            </div>

            <div className="alliance-footer">
                <div style={{ display: "flex", flexDirection: "row" }}>

                    <div className="col-md-4">
                        <ul style={{ marginTop: 20, color: "red", marginLeft: 30 }}>
                            <li style={{ color: "dark-red" }}>

                            { i18n.language == 'my' ?
                                <p className="small-message-bold text-blue-theme">
                                    <i>Daftar pembiayaan perniagaan<br />secara atas talian</i>
                                </p>
                            : <p className="small-message-bold text-blue-theme"><i>Register for business financing<br />through online</i></p>
                            }
                            </li>
                            <br />
                            <li style={{ color: "dark-red" }}>
                                <p className="small-message-bold text-blue-theme">
                                    <i>{i18n.t('specialBankingRates')}</i>
                                </p>
                            </li>
                        </ul>
                    </div>

                    <div className="col-md-4">
                        <ul style={{ marginTop: 20, color: "red", marginLeft: 70 }}>
                            <li style={{ color: "dark-red" }}>
                                <p className="small-message-bold text-blue-theme">
                                    <i>{i18n.t('yourOnlinePromotion')}</i>
                                </p>
                            </li>
                            <br />
                            <br />
                            <li style={{ color: "dark-red" }}>
                                <p className="small-message-bold text-blue-theme">
                                    <i>{i18n.t('digitalMarketingSolutions')}</i>
                                </p>
                            </li>
                        </ul>
                    </div>

                    <div className="col-md-4">
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <p className="text-blue-theme medium-message mb-4 mr-3" style={{ marginTop: 20 }}>
                                <b>{i18n.t('registerNow')}</b>
                            </p>

                            <div className="text-white circle-icon text-center" style={{ cursor: "pointer", marginTop: 20 }} onClick={nextPage}>
                                <span style={{ color: "white" }}>{<ArrowForward />}</span>
                            </div>
                        </div>

                        { i18n.language == 'my' ? 
                            <p className="small-message text-blue-theme" style={{ marginTop: 30, width: "80%" }}>
                                <i>Nikmati ganjaran sehingga RM300 dalam bentuk tunai dan e-voucher apabila anda mendaftar hari ini</i><br />
                                (untuk tempoh terhad sahaja)
                            </p>
                            : <p className="small-message text-blue-theme" style={{ marginTop: 30, width: "80%" }}>
                                <i>Enjoy rewards up to RM300 in cash and e-vouchers when you register today</i><br />
                                (for a limited time only)
                            </p>
                        }
                    </div>

                </div>
            </div>

        </div>
    );
}

function MobileView() {
    return (
        <div id="alliance_page" className="row alliance-mobile" style={{ justifyContent: "space-between" }}>
            <div style={{ marginTop: 20, textAlign: "center" }}>
                <img src={AllianceBankLogo} className="alliance-image" alt="alliance1" style={{ paddingBottom: 5 }} />
                <img src={MeniagaLogo} className="meniaga-image" alt="alliance2" />
            </div>

            <div style={{ width: "100%" }}>
                <div className="text-blue-theme text-center large-message mt-5 mb-5" style={{ fontSize: "35px" }}>
                    {i18n.t('digitalPackageAndFinancing')}
                </div>

                <p className="text-blue-theme medium-message mb-5">
                    {i18n.t('enterpriseAndSme')}
                </p>

                { i18n.language == 'my' ? 
                <p className="small-message text-blue-theme" style={{ lineHeight: 2 }}>
                    Mari terokai pakej digital dan pembiayaan khas untuk perniagaan
                    dan perusahaan kecil dan sederhana (SME) di bawah komuniti <b><i>meniaga</i></b>.<br />
                    Kami ingin membantu meluaskan perniagaan anda kepada lebih ramai
                    pelanggan, mendapat akses pembiayaan dan mendigitalkan perniagaan anda.
                </p>
                : <p className="small-message text-blue-theme" style={{ lineHeight: 2 }}>
                    Explore the special packages and financing offered for businesses and small and medium enterprises (SMEs) under the business community.<br />
                    We want to help expand your business to more customers, gain access to bank financing and digitalize your business.
                </p>
                }

                <img src={MeniagaGraph} className="large-image mt-2" alt="meniaga-graph" style={{ margin: "auto" }} />

                <div className="col-md-4">
                    <ul style={{ marginTop: 40, marginBottom: 40, color: "red", marginLeft: 30 }}>
                        <li style={{ color: "dark-red" }}>
                        { i18n.language == 'my' ? 
                            <p className="small-message-bold text-blue-theme">
                                <i>Daftar pembiayaan perniagaan<br />secara atas talian</i>
                            </p>
                            : <p className="small-message-bold text-blue-theme">
                                <i>Register for business financing<br />through online</i>
                            </p>
                        }
                        </li>
                        <li style={{ color: "dark-red" }}>
                            <p className="small-message-bold text-blue-theme">
                                <i>{i18n.t('yourOnlinePromotion')}</i>
                            </p>
                        </li>
                        <li style={{ color: "dark-red" }}>
                            <p className="small-message-bold text-blue-theme">
                                <i>{i18n.t('specialBankingRates')}</i>
                            </p>
                        </li>
                        <li style={{ color: "dark-red" }}>
                            <p className="small-message-bold text-blue-theme">
                                <i>{i18n.t('digitalMarketingSolutions')}</i>
                            </p>
                        </li>
                    </ul>
                </div>

            </div>
            <div>
                <div className="alliance-form-footer-mobile" style={{ paddingTop: 80 }}>
                    <p className="text-blue-theme medium-message mr-3">
                        <b>{i18n.t('registerNow')}</b>
                    </p>
                    <div className="text-white circle-icon text-center" style={{ cursor: "pointer" }}>
                        <a href="/alliance_form" style={{ color: "white" }}>{<ArrowForward />}</a>
                    </div>

                </div>
                <br />
                { i18n.language == 'my' ?
                <p className="small-message text-blue-theme" style={{ marginTop: 40, textAlign: "justify" }}>
                    <i>Nikmati ganjaran sehingga RM300 dalam bentuk tunai dan e-voucher apabila anda mendaftar hari ini</i><br />
                    (untuk tempoh terhad sahaja)
                </p>
                : <p className="small-message text-blue-theme" style={{ marginTop: 40, textAlign: "justify" }}>
                    <i>Enjoy rewards up to RM300 in cash and e-vouchers when you register today</i><br />
                    (for a limited time only)
                </p>
                }
            </div>

        </div>
    );
}

function AllianceBankPage() {
    return (
        <div>
            <div className="desktop-view">
                <DesktopView />
            </div>
            <div className="mobile-view">
                <MobileView />
            </div>
        </div>
    );
}

export default AllianceBankPage;