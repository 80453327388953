
import React, { useEffect } from 'react';
import ReactLoading from 'react-loading';
import {
  isAndroid,
  isIOS,
  isMobile,
} from "react-device-detect";

function ReferralCodeAutoFilled(props) {
  const referral_code = props.match.params.referral_code;
  const height = isMobile ? 40 : 100;
  const width = isMobile ? 40 : 100

  useEffect(() => {
    setTimeout(() => {
      if(isIOS){          //redirect to store if using device
        return window.location.href = "https://apps.apple.com/my/app/meniaga-my/id1516839999"
      } else if(isAndroid){
        return window.location.href = "https://play.google.com/store/apps/details?id=com.invoke.meniaga.my";
      }else {             //redirect to landing page if using browser
        return window.location.href = "https://meniaga.my";
      }
    },50)
    window.location.href = `meniaga:///registration?referral_code=${referral_code}`
  },[referral_code])

  return (
    <div className="redirect" >
      <h3 className="text-blue-theme"  >{`Sedang buka aplikasi... `}</h3>
      <ReactLoading className="desktop-loading" type={'spin'} color={'#00334C'} height={height} width={width} />
    </div>
  );
}

export default ReferralCodeAutoFilled;
