import React from 'react';

import "../App.css";

import i18n from '../i18n.config';

function DesktopView() {
    return (
        <div className="fourth">
            <div className="row h-30" style={{ paddingTop: "50px" }}>
                <div className="col-md-12">
                    <div className="text-blue-theme text-raleway" style={{ fontSize: "55px", marginBottom: "20px" }}>
                        {i18n.t('faq')}
                    </div>
                </div>
            </div>
            <div className="row h-70 pt-3">
                <div className="col-md-6" style={{ paddingRight: "100px" }}>
                    <p className="text-raleway small-message" style={{ lineHeight: "1.6em" }}>
                        <span className="question">{i18n.t('question1')}</span><br />
                        {i18n.language == 'my' ?
                            <span className='answer'>e-Gerai boleh didapati di aplikasi telefon pintar sahaja. Muat turun dari App Store atau Google Play Store.</span>
                            : <span className='answer'>e-Gerai only available on the <b>meniaga</b> app. You can download on App Store or Google Play Store.</span>
                        }
                        <br/><br/>
                        
                        <span className="question">{i18n.t('question2')}</span><br />
                        <span className="answer">{i18n.t('answer2')}</span><br />
                        <br />

                        {/* NEED NEW COPYWRITTING */}
                        {/* <span className="question">Semua jual beli bayar tunai sahaja?</span><br />
                        <span className="answer">Ya, sebab harga juadah makanan, ayam, daging, telur dan sayur selalunya tidak melebihi RM20 setiap satu. Lebih mudah bayar tunai kerana jual beli dalam kawasan setempat sahaja, sama seperti beli di gerai.</span><br />
                        <br /> */}

                        <span className="question">{i18n.t('question4')}</span><br />
                        <span className="answer">{i18n.t('answer4')}</span><br />
                        <br />
                        <span className="question">{i18n.t('question5')}</span><br />
                        <span className="answer">{i18n.t('answer5')}</span><br />
                        <br />
                        <span className="question">{i18n.t('question6')}</span><br />
                        {i18n.language == 'my' ?
                            <span className="answer">Peniaga dan pembeli boleh berhubung melalui telefon atau pesanan WhatsApp. Hanya tekan butang telefon atau WhatsApp dan boleh bercakap terus untuk mengesahkan tempahan atau pembelian. Maklumat pembeli atau peniaga di hujung jari tanpa perlu keluar dari aplikasi <b>meniaga</b>.</span>
                            : <span className="answer">Merchants and buyers can communicate via phone or WhatsApp messages. You can call or Whatsapp directly to confirm your booking. All information is available on the app.</span>
                        }
                    </p>
                </div>
                <div className="col-md-6" style={{ paddingRight: "100px" }}>
                    <p className="text-raleway small-message" style={{ lineHeight: "1.6em" }}>
                        <span className="question">{i18n.t('question7')} <b>meniaga</b>?</span><br />
                        <span className="answer">{i18n.t('answer7')}</span><br />
                        <br />

                        {i18n.language == 'my' ?

                            <span className="question">Apa istimewa platform <b>meniaga</b> ini yang membolehkan ia menjadi platform Cash-On-Delivery (COD) yang lebih baik?</span>
                            : <span className="question">Why <b>meniaga</b> is a better COD platform?</span>
                        }
                        <br />

                        {i18n.language == 'my' ? 
                        <span className="answer">Apabila peniaga telah menghantar tempahan dan menerima bayaran, <b>meniaga</b> akan menjana satu set kod khusus yang perlu dimasukkan oleh peniaga dan pembeli. Hanya apabila kedua-dua kod ini telah dimasukkan, barulah urusniaga itu dilabel sebagai sempurna. Ini membolehkan <b>meniaga</b> membina profil peniaga dan pembeli berdasarkan rekod urusniaga yang sempurna dan tidak sempurna. Profil ini digunakan untuk membina algoritma yang menghalang peniaga dan pembeli yang mempunyai rekod buruk dari menggunakan <b>meniaga</b>.</span>
                        : <span className="answer">A code will be generated once the merchant sends in an order and receives the payment for it. Both the merchant and buyer will require to key in the code before the transaction will be labeled as a success. This will allow <b>meniaga</b> to build the profile for both merchant and buyer based on their successful and failed transaction records.</span>
                        }

                        <br />
                        <br />
                        {/* <span className="question">{i18n.t('question9')} <b>meniaga</b>?</span><br /> */}

                        {i18n.language == 'my' ?
                            <span className='question'>Kenapa perlu ada platform Cash-On-Delivery <b>meniaga</b>?</span>
                            : <span className='question'>Why should <b>meniaga</b> have an option for Cash-On-Delivery (COD)?</span>
                        }
                        <br />

                        {i18n.language == 'my' ? 
                        <span className="answer">Platform tempahan dan penghantaran makanan sedia ada tidak membenarkan urusniaga Cash-On-Delivery kerana wang jualan disimpan oleh syarikat platform terlebih dahulu. Syarikat kemudian menolak caj komisen mereka antara 20% hingga 30%, kemudian baru jumlah bersih jualan dimasukkan ke akaun peniaga. Hanya peniaga tertentu yang mempunyai saiz perniagaan tertentu yang mampu bertahan dengan model begini sedangkan semua peniaga mahu mendapatkan kemudahan tempahan secara digital. <b>Meniaga</b> mendigitalkan perniagaan kecil anda tanpa mengenakan caj.</span>
                        : <span className="answer">Existing food ordering and delivery platforms does not allow Cash-On-Delivery transactions as the money will be kept by the platform's company in advance. The platform's company will then charge a commission fee between 20% to 30% before crediting the remaining balance into the merchant's bank account. Usually, only the merchants with a certain size of business would be able to survive this kind of model when all merchants will want to benefit from online orders. <b>Meniaga</b> offers the opportunity to digitalise your business at no charge. </span>
                        }
                        <br />
                        <br />
                        <span className="question">{i18n.t('question10')} <b>meniaga</b>?</span><br />
                        {i18n.language == 'my' ?
                        <span className="answer">Ada butang untuk kongsi gerai anda di platform <b>meniaga</b> di laman media sosial, WhatsApp dan Telegram. Setelah anda membuka gerai dan mengisi katalog produk anda, pastikan anda kongsi gerai anda di laman media sosial, WhatsApp dan Telegram dengan menggunakan butang tersebut.</span>
                        : <span className="answer">There is a button which allows merchants to generate a link to their e-store which can be shared on social media such as Whatsapp, Telegram etc. Once you have registered your e-store and filled up your products onto the catalog, please make sure that you share your e-store link so that potential buyers could easily access your store. </span>
                        }
                        <br />
                        <br />
                        <span className="question">{i18n.t('question11')}</span><br />
                        <span className="answer">{i18n.t('answer11')}</span>
                    </p>
                </div>
            </div>
        </div>
    );
}

function MobileView() {
    return (
        <div className="fourth-mobile">
            <div className="row" style={{ paddingTop: "80px" }}>
                <div className="text-blue-theme text-raleway text-center" style={{ fontSize: "45px", marginBottom: "20px", width: "100%" }}>
                    {i18n.t('faq')}
                </div>

                <div style={{ width: "100%" }}>
                    <p className="text-raleway small-message text-center" style={{ lineHeight: "1.6em" }}>
                        <span className="question">{i18n.t('question1')}</span><br />
                        <span className="answer">{i18n.t('answer1')}</span><br />
                        <br />
                        <span className="question">{i18n.t('question2')}</span><br />
                        <span className="answer">{i18n.t('answer2')}</span><br />
                        <br />

                        {/* NEED NEW COPYWRITTING */}
                        {/* <span className="question">Semua jual beli bayar tunai sahaja?</span><br />
                        <span className="answer">Ya, sebab harga juadah makanan, ayam, daging, telur dan sayur selalunya tidak melebihi RM20 setiap satu. Lebih mudah bayar tunai kerana jual beli dalam kawasan setempat sahaja, sama seperti beli di gerai.</span><br />
                        <br /> */}

                        <span className="question">{i18n.t('question4')}</span><br />
                        <span className="answer">{i18n.t('answer4')}</span><br />
                        <br />
                        <span className="question">{i18n.t('question5')}</span><br />
                        <span className="answer">{i18n.t('answer5')}</span><br />
                        <br />
                        <span className="question">{i18n.t('question6')}</span><br />
                        
                        {i18n.language == 'my' ?
                        <span className="answer">Peniaga dan pembeli boleh berhubung melalui telefon atau pesanan WhatsApp. Hanya tekan butang telefon atau WhatsApp dan boleh bercakap terus untuk mengesahkan tempahan atau pembelian. Maklumat pembeli atau peniaga di hujung jari tanpa perlu keluar dari aplikasi <b>meniaga</b>.</span>
                        : <span className="answer">Merchants and buyers can communicate via phone or WhatsApp messages. You can call or whatsapp directly to confirm your booking. All information is at your fingertips without having to close the app.</span>
                        }
                        <br /><br />

                        {i18n.language == 'my' ?
                        <span className="question">Apa istimewa platform <b>meniaga</b> ini yang membolehkan ia menjadi platform Cash-On-Delivery (COD) yang lebih baik?</span>
                        : <span className="question">What’s so special about <b>meniaga</b> that allows it to be a better Cash-On-Delivery (COD) platform?</span>
                        }
                        <br /> <br />

                        {i18n.language == 'my' ? 
                        <span className="answer">Apabila peniaga telah menghantar tempahan dan menerima bayaran, <b>meniaga</b> akan menjana satu set kod khusus yang perlu dimasukkan oleh peniaga dan pembeli. Hanya apabila kedua-dua kod ini telah dimasukkan, barulah urusniaga itu dilabel sebagai sempurna. Ini membolehkan <b>meniaga</b> membina profil peniaga dan pembeli berdasarkan rekod urusniaga yang sempurna dan tidak sempurna. Profil ini digunakan untuk membina algoritma yang menghalang peniaga dan pembeli yang mempunyai rekod buruk dari menggunakan <b>meniaga</b>.</span>
                        : <span className="answer">Once the merchant has sent an order and received payment, it will generate codes that need to be entered by the merchant and buyer. Only when these two codes have been entered, then the transaction is labeled as success. This allows <b>meniaga</b> to build merchant and buyer profiles based on the success and failure transaction records. </span>
                        }
                        <br /><br />

                        <span className="question">{i18n.t('question9')} <b>meniaga</b>?</span><br />
                        {i18n.language == 'my' ?
                        <span className="answer">Platform tempahan dan penghantaran makanan sedia ada tidak membenarkan urusniaga Cash-On-Delivery kerana wang jualan disimpan oleh syarikat platform terlebih dahulu. Syarikat kemudian menolak caj komisen mereka antara 20% hingga 30%, kemudian baru jumlah bersih jualan dimasukkan ke akaun peniaga. Hanya peniaga tertentu yang mempunyai saiz perniagaan tertentu yang mampu bertahan dengan model begini sedangkan semua peniaga mahu mendapatkan kemudahan tempahan secara digital. <b>meniaga</b> mendigitalkan perniagaan kecil anda tanpa mengenakan caj.</span>
                        : <span className="answer">Existing ordering and delivery platforms does not allow Cash-On-Delivery transactions because the money will be saved by the platform company in advance. The company then deducts their commission charges between 20% to 30%, then the new net sales amount will be credited to the merchant's account. Only certain merchants with a certain size of business are able to survive with this model whereas all merchants want to get digital booking facilities. <b>meniaga</b> digitalizes your small business at no charge. </span>
                        }
                        <br /><br />
                        
                        <span className="question">{i18n.t('question10')} <b>meniaga</b>?</span><br />
                        {i18n.language == 'my' ?
                        <span className="answer">Ada butang untuk kongsi gerai anda di platform <b>meniaga</b> di laman media sosial, WhatsApp dan Telegram. Setelah anda membuka gerai dan mengisi katalog produk anda, pastikan anda kongsi gerai anda di laman media sosial, WhatsApp dan Telegram dengan menggunakan butang tersebut.</span>
                        : <span className="answer">There are buttons to share your e-Gerai on <b>meniaga</b> to be shared on social media sites, WhatsApp and Telegram. Once you have open your e-Gerai and fill out your product in the catalog, make sure you share your e-Gerai on social media sites, WhatsApp and Telegram using those buttons.</span>
                        }
                        <br /><br />
                        <span className="question">{i18n.t('question11')}</span><br />
                        <span className="answer">{i18n.t('answer11')}</span>
                    </p>
                </div>
            </div>
        </div>
    );
}

function FourthPage() {
    return (
        <div>
            <div className="desktop-view">
                <DesktopView />
            </div>
            <div className="mobile-view">
                <MobileView />
            </div>
        </div>
    );
}

export default FourthPage;
